import { useRouter } from "next/router";
import { PropsWithChildren, useEffect, useMemo } from "react";
import Header from "../components/Header";
import ChatbotView from "./Chatbot/ChatbotView";
import { NotificationBar } from "./NotificationBar";
import Layout from "./SidebarWideView";

const LayoutWithHeader = ({ children }: PropsWithChildren<{}>) => {
  const router = useRouter();

  useEffect(() => {
    const closeValChat = (event: any) => {
      if (
        event.key === "Escape" &&
        !document.getElementById("headlessui-portal-root")
      ) {
        window.sessionStorage.setItem("showValStatus", "false");
        window.dispatchEvent(new Event("storage"));
      }
    };
    window.addEventListener("keydown", (event) => closeValChat(event));
    return () => {
      window.removeEventListener("keydown", (event) => closeValChat(event));
    };
    // eslint-disable-next-line
  }, []);

  const ChatBoxView = useMemo(() => {
    return (
      <div className={`flex flex-col`}>
        <ChatbotView defaultWidth={240} />
      </div>
    );
  }, []);

  const HeaderView = useMemo(() => {
    return <Header />;
  }, []);

  return ["/signin"].includes(router.pathname) ? (
    <>{children}</>
  ) : (
    <div>
      {ChatBoxView}
      <Layout>
        <div className="flex">
          <div className="grow mt-6 w-full">
            {HeaderView}
            {children}
          </div>
        </div>
      </Layout>
      <NotificationBar />
    </div>
  );
};

export default LayoutWithHeader;
