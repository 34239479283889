import Link from "next/link";
import { OpportunityProps } from "../../api/types";
import Avatar from "../Avatar";

export const OpportunityLinkedDeal = ({ opportunity }: OpportunityProps) => {
  return (
    <Link
      href={`/opportunity/${opportunity?.id}`}
      key={opportunity?.id}
      className="flex flex-row items-center justify-between text-xs rounded-xl bg-white border border-gray-200 shadow-md gap-x-2 pr-2 h-8 min-w-max"
    >
      <div
        className={`h-full w-1.5 rounded-l-2xl ${
          opportunity?.status === "active"
            ? "bg-green-500"
            : opportunity?.status === "closed"
            ? "bg-red-500"
            : opportunity?.status === "revisit"
            ? "bg-yellow-500"
            : ""
        }`}
      />
      <div className="font-bold">{opportunity?.name}</div>
      <Avatar
        name={opportunity?.owner?.name}
        size={20}
        className="shrink-0"
        url={opportunity?.owner?.image_url}
      />
    </Link>
  );
};
