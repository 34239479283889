import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { downloadAttachment, getBrowserName } from "./Utils/fileUtils";

const SET_SHOW_DOWNLOAD_BUTTON_DELAY = 800;

export const AttachmentPreview = () => {
  const router = useRouter();
  const { url, fileId, fileName } = router.query;
  const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false);
  const frameRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const browserName = getBrowserName();
    if (browserName !== "safari") {
      setTimeout(() => {
        setShowDownloadButton(true);
      }, SET_SHOW_DOWNLOAD_BUTTON_DELAY);
    }
  }, []);

  const downloadFile = () => {
    downloadAttachment({
      fileId: parseInt(fileId as string),
      fileName: fileName as string,
    });
  };

  return (
    <div className="w-full h-full relative">
      <iframe
        ref={frameRef}
        id={"myIframe"}
        className="w-full h-full"
        src={url as string}
      />
      <div
        className={`absolute flex flex-col justify-center items-center w-[35px] h-[35px] top-[10px] right-[86px] ${
          showDownloadButton ? "bg-[#323639]" : "opacity-0"
        }`}
      ></div>
      <button
        className={`absolute flex flex-col justify-center items-center w-[35px] h-[35px] top-[10px] right-[86px] ${
          showDownloadButton
            ? "text-white bg-[#323639] hover:bg-[#595858a3] hover:rounded-full"
            : "opacity-0 bg-[transparent]"
        }`}
        onClick={() => downloadFile()}
      >
        <ArrowDownTrayIcon className="h-5 w-5" />
      </button>
    </div>
  );
};
