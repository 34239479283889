import {
  CheckIcon,
  EyeIcon,
  PencilIcon,
  PlusIcon,
  SparklesIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import {
  deleteTemplatePrompt,
  getAgentPersonaById,
  getAllTemplatePrompt,
} from "../../api";
import {
  AgentPersona,
  AgentPersonaStub,
  TemplatePrompt,
} from "../../api/types";
import Avatar from "../Avatar";
import { ConfirmationModal } from "../ConfirmationModal";
import ErrorModal from "../ErrorModal";
import NewModal from "../NewModal";
import { parseAxiosError } from "../utils";
import { TemplatePromptForm } from "./TemplatePromptForm";

type PromptsGuideViewProps = {
  onPromptSelected: (prompt: TemplatePrompt) => void;
  onGetPrompts: (prompts: TemplatePrompt[]) => void;
  promptSelecting: TemplatePrompt | undefined;
  agent: AgentPersonaStub;
  showAskVal: boolean;
};

export const PromptsGuideView = ({
  onPromptSelected,
  onGetPrompts,
  promptSelecting,
  agent,
  showAskVal,
}: PromptsGuideViewProps) => {
  const [agentDetail, setAgentDetail] = useState<AgentPersona>();
  const [prompts, setPrompts] = useState<TemplatePrompt[]>([]);
  const [showPromptForm, setShowPromptForm] = useState(false);
  const [promptDetail, setPromptDetail] = useState<TemplatePrompt>();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const [showConfirmDeletionPrompt, setShowConfirmDeletionPrompt] =
    useState<boolean>(false);
  const [deletedPrompt, setDeletedPrompt] = useState<boolean>(false);
  const [deletingPrompt, setDeletingPrompt] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const personaContainer = useRef<HTMLDivElement>(null);
  const [showMoreLess, setShowMoreLess] = useState<boolean>(false);
  const [more, setMore] = useState<boolean>(false);
  const updatePrompts = (prompts: TemplatePrompt[]) => {
    setPrompts(prompts);
    onGetPrompts(prompts);
  };

  useEffect(() => {
    if (showAskVal) {
      Promise.all([
        getAgentPersonaById(agent.id),
        getAllTemplatePrompt(agent.id),
      ])
        .then((response) => {
          const [agentResponse, promptsResponse] = response;
          setAgentDetail(agentResponse.data.data);
          updatePrompts(promptsResponse.data.data);
        })
        .catch((error: AxiosError) => {
          setErrorMessage(parseAxiosError(error));
          setShowErrorModal(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent, showAskVal]);

  useEffect(() => {
    if (personaContainer.current) {
      const isMore =
        personaContainer.current.getBoundingClientRect().height > 180;
      setShowMoreLess(isMore);
      setMore(isMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personaContainer, agent]);

  const deletePrompt = (promptId: number) => {
    setDeletingPrompt(true);
    deleteTemplatePrompt(promptId)
      .then((response: any) => {
        if (response.status === 200) {
          updatePrompts(prompts.filter((prompt) => prompt.id !== promptId));
          setDeletedPrompt(true);
          setDeletingPrompt(false);
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
        setDeletingPrompt(false);
      });
  };

  if (!agentDetail) {
    return <></>;
  }

  const policyGroups =
    agentDetail.access_policy?.groups &&
    agentDetail.access_policy?.groups.length > 0 &&
    agentDetail.access_policy?.users &&
    agentDetail.access_policy?.users.length > 1
      ? agentDetail.access_policy?.groups
          .map((group) => group.name)
          .join(", ") + " & Selected Users"
      : agentDetail.access_policy?.groups &&
        agentDetail.access_policy?.groups.length > 0
      ? agentDetail.access_policy?.groups.map((group) => group.name).join(", ")
      : agentDetail.access_policy?.users &&
        agentDetail.access_policy?.users.length > 0
      ? "Selected Users"
      : "all";

  return (
    <div className="flex flex-col items-start overflow-y-auto gap-y-4 h-full p-3">
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-row gap-x-5 items-center">
          <div className="text-xl font-bold">{agent.name}</div>
          <div className="group flex flex-row font-xs text-gray-500 items-center">
            <EyeIcon className="h-6 w-6 cursor-pointer" />
            <div className="absolute invisible top-11 group-hover:visible border bg-white rounded-md text-black shadow-md text-xs p-2">
              {policyGroups.toLowerCase()[0].toUpperCase() +
                policyGroups.toLowerCase().substring(1)}
              <span className="flex mt-2">
                {agentDetail.access_policy?.users &&
                agentDetail.access_policy?.users.length > 0
                  ? agentDetail.access_policy?.users.map((user) => (
                      <div key={user.id}>
                        {user.id !== agentDetail.created_by.id ? (
                          <Link
                            key={user.id}
                            href={`/people/${user.id}`}
                            passHref
                            legacyBehavior
                          >
                            <div className="relative owner cursor-pointer">
                              <Avatar
                                key={user.id}
                                className="bg-gray-300 ml-1"
                                name={user.name}
                                size={20}
                                url={user.image_url}
                              />
                              <span className="absolute bg-white top-6 justify-center py-1 ownername border rounded-md px-2 hidden">
                                {user.name}
                              </span>
                            </div>
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    ))
                  : ""}
              </span>
            </div>
          </div>
        </div>
        <div className="text-2xs text-gray-500">
          Created by: {agentDetail.created_by?.name ?? "Default"}
        </div>
        <div
          ref={personaContainer}
          className={`text-xs ${more ? "line-clamp-10" : ""}`}
        >
          {agent.persona}
        </div>
        {showMoreLess && (
          <div className="flex flex-row w-full justify-start text-xs font-semibold">
            <button
              className="mr-2 text-blue-menu"
              onClick={() => setMore(!more)}
            >
              {more ? "View more" : "View less"}
            </button>
          </div>
        )}
      </div>
      {agentDetail.tools.length > 0 && (
        <div className="flex flex-col gap-y-2">
          <div className="text-xl font-bold">Tools</div>
          <div className="grid grid-cols-2 gap-x-2">
            {agentDetail.tools.map((tool) => {
              return (
                <div
                  key={tool.name}
                  className="flex flex-row gap-x-2 items-center text-xs"
                >
                  <div className="h-5 w-5">
                    <CheckIcon className="h-5 w-5" />
                  </div>
                  <div>{tool.display}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="flex flex-row items-center mt-5">
        <SparklesIcon className="w-5 h-5 mr-2" />
        <div className="text-xl font-bold">Prompts</div>
        <div
          className="flex flex-row gap-x-1 ml-5 items-center text-xs bg-white py-2 px-4 rounded-full shadow-md hover:shadow-slate-400 cursor-pointer"
          onClick={() => {
            setPromptDetail(undefined);
            setShowPromptForm(true);
          }}
        >
          <PlusIcon className="h-3 w-3 stroke-2" />
          <div className="font-semibold">Create Prompt</div>
        </div>
      </div>
      <div className="flex flex-wrap gap-2 w-full">
        {prompts
          .sort((a: TemplatePrompt, b: TemplatePrompt) =>
            a.title.localeCompare(b.title)
          )
          .map((item: TemplatePrompt) => (
            <div
              key={item.id}
              className={`group flex flex-row items-center text-xs font-medium bg-white py-2 px-4 rounded-full cursor-pointer shadow-md hover:shadow-slate-400 ${
                item.id === promptSelecting?.id &&
                "bg-blue-100 text-blue-900 font-semibold"
              }`}
              onClick={() => onPromptSelected(item)}
            >
              <div>{item.title}</div>
              <div className="flex flex-row ml-2 gap-x-1">
                <div
                  className="w-4 h-4"
                  onClick={() => {
                    setPromptDetail(item);
                    setShowPromptForm(true);
                  }}
                >
                  <PencilIcon className="h-4 w-4 stroke-gray-500 hover:stroke-blue-menu" />
                </div>
                <div
                  className="w-4 h-4"
                  onClick={() => {
                    setDeleteId(item.id);
                    setDeletedPrompt(false);
                    setShowConfirmDeletionPrompt(true);
                  }}
                >
                  <TrashIcon className="h-4 w-4 stroke-gray-500 hover:stroke-red-500" />
                </div>
              </div>
            </div>
          ))}
      </div>
      <NewModal
        title={promptDetail ? "Edit Prompt" : "Create Prompt"}
        expanded={false}
        open={showPromptForm}
        onClose={setShowPromptForm}
      >
        <TemplatePromptForm
          agentId={agent.id}
          templatePrompt={promptDetail}
          onSuccess={(prompt, isCreateNew) => {
            if (promptDetail && !isCreateNew) {
              prompts.forEach((item, index) => {
                if (item.id === promptDetail.id) {
                  prompts[index] = prompt;
                  return false;
                }
              });
              updatePrompts(prompts);
            } else {
              updatePrompts([...prompts, prompt]);
            }
            setPromptDetail(undefined);
            setShowPromptForm(false);
          }}
        />
      </NewModal>
      <ErrorModal
        open={showErrorModal}
        setOpen={setShowErrorModal}
        errorMessage={errorMessage}
      />
      <ConfirmationModal
        open={showConfirmDeletionPrompt}
        title="Delete prompt?"
        subtitle="This action cannot be undone"
        succeed={deletedPrompt}
        succeedMessage={`You deleted this prompt.`}
        processing={deletingPrompt}
        processingText={"Deleting..."}
        submitButtonText={"Delete"}
        onClose={() => {
          setDeleteId(0);
          setShowConfirmDeletionPrompt(false);
        }}
        onSubmit={() => deletePrompt(deleteId)}
      />
    </div>
  );
};
