import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

type ResizableComponentProps = {
  expandedWidth?: number;
  expanded: boolean | null;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
  width: number;
  widthFull?: boolean;
};

const VAL_WIDTH_KEY = "widthValStatus";

const setWidthValStatus = (percentageChatBox: number) => {
  window.sessionStorage.setItem(VAL_WIDTH_KEY, percentageChatBox.toString());
  window.dispatchEvent(new Event("storage"));
};

const ResizableComponent = ({
  expandedWidth,
  expanded,
  children,
  width,
  setWidth,
  widthFull = false,
}: PropsWithChildren<ResizableComponentProps>) => {
  const [isResizing, setIsResizing] = useState(false);
  const [initialX, setInitialX] = useState(0);
  const [originalWidth, setOriginalWidth] = useState(0);
  const container = useRef<HTMLDivElement>(null);

  const screenWidth = window.innerWidth;
  const minWidth = screenWidth / 2;
  const maxWidth = screenWidth * 0.95;

  const handleMouseDown = (e: any) => {
    setIsResizing(true);
    setInitialX(e.clientX);
    setOriginalWidth(width);
    // Disable text selection during resizing
    document.body.classList.add("disable-selection");
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    // Re-enable text selection after resizing
    document.body.classList.remove("disable-selection");
  };

  useEffect(() => {
    const handleResize = (event: any) => {
      if (container.current) {
        const monitorWidth = screen.width;
        const screenWidth = window.innerWidth;
        const width = Number(window.sessionStorage.getItem(VAL_WIDTH_KEY));
        const currentWidth = width && width > 0 ? width * screenWidth : 800;
        if (screenWidth / monitorWidth < 0.3) {
          setWidth(screenWidth > minWidth ? screenWidth : minWidth);
        } else {
          setWidth(currentWidth > minWidth ? currentWidth : minWidth);
        }
      }
    };
    window.addEventListener("resize", (event) => handleResize(event));
    return () => {
      window.removeEventListener("resize", (event) => handleResize(event));
    };
    // eslint-disable-next-line
  }, []);

  const handleMouseMove = (e: any) => {
    if (isResizing) {
      const newWidth =
        originalWidth -
        (e.clientX - initialX) +
        (expanded ? 0 : expandedWidth ?? 0);

      if (newWidth > minWidth && newWidth < maxWidth) {
        setWidth(newWidth);
        setWidthValStatus(newWidth / screenWidth);
      } else if (newWidth <= minWidth) {
        setWidth(minWidth);
        setWidthValStatus(minWidth / screenWidth);
      } else {
        setWidth(maxWidth);
        setWidthValStatus(maxWidth / screenWidth);
      }
    }
  };

  useEffect(() => {
    if (isResizing) {
      setWidthValStatus(width / screenWidth);
    }
    // eslint-disable-next-line
  }, [width]);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    // eslint-disable-next-line
  }, [isResizing]);

  return (
    <div
      ref={container}
      className={`h-full relative ${isResizing ? "select-none" : ""}`}
      style={{
        width: !widthFull ? `${width}px` : "100vw",
      }}
    >
      <div
        className="w-2 h-full absolute z-top left-0 bottom-0 cursor-ew-resize"
        onMouseDown={handleMouseDown}
      />
      {children}
    </div>
  );
};

export default ResizableComponent;
