import { useField } from "formik";
import { classNames } from "../utils";
import VMFSelect from "./VMFSelect";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import NewModal from "../NewModal";
import VertexPortfolioFundForm from "../VPC/VertexPortfolioFundForm";
import { ModalHeader } from "../ModalHeader";

type FVMFSelectedFieldProps = {
  name: string;
  label: string;
  required: boolean;
  errors: string | undefined;
  touched: boolean;
  isMulti?: boolean;
  isAddNew?: boolean;
  fundType?: "parent" | "child";
};
const FormVMFSelectedField = ({
  name,
  required,
  label,
  errors,
  touched,
  isMulti = true,
  isAddNew = false,
  fundType,
}: FVMFSelectedFieldProps) => {
  const [input, meta, helpers] = useField(name);
  const { value } = input;
  const { setValue } = helpers;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="grid w-full md:w-11/12">
      <div className="flex">
        <label className="font-semibold text-xs" htmlFor={name}>
          {label}
        </label>
        {required ? (
          <div className="text-red-600 text-sm font-semibold">*</div>
        ) : (
          ""
        )}
      </div>
      <div className="relative flex items-center gap-2">
        <div
          className={classNames(
            "w-full items-center border border-1 relative h-fit rounded-md",
            touched && errors ? "border-red-500" : "border-blue-menu"
          )}
        >
          <VMFSelect
            id={name}
            value={value}
            onChange={(newValue) => setValue(newValue)}
            isMulti={isMulti}
            fundType={fundType}
          />
        </div>
        {isAddNew && (
          <div
            className="bg-blue-menu flex justify-center items-center w-5 h-5 rounded-full cursor-pointer absolute -right-7"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <PlusIcon className="h-5 w-5 text-white" />
          </div>
        )}
      </div>

      <div
        className={classNames(
          touched && errors ? "text-2xs text-red-600" : "hidden"
        )}
      >
        {errors}
      </div>
      <NewModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        showExpandButton={false}
        expanded={false}
        customClass="!overflow-visible"
      >
        <ModalHeader title="Create Fund" icon="PlusCircleIcon" />
        <VertexPortfolioFundForm
          callback={(response: any) => {
            setValue({ value: response.id, label: response.name });
            setIsOpen(false);
          }}
        />
      </NewModal>
    </div>
  );
};

export default FormVMFSelectedField;
