import axios from "axios";
import { SuggestionDataItem } from "react-mentions";
import * as types from "./types";
import {
  AccessPolicy,
  BaseParams,
  CreateContactParams,
  CreateGTMEventsParams,
  CreateGTMNewsParams,
  CreateGTMProjectParams,
  CreateOpportunityParams,
  CreatePortfolioParams,
  CreateTableStateParams,
  EmailParams,
  FunnelStageParams,
  generativeAICorporateParams,
  generativeAIParams,
  generativeAIVPCParams,
  getOnePagerContentParams,
  InvestmentFundParams,
  NotificationData,
  OpportunityAutocompleteResult,
  Success,
  TemplateBlockParams,
  TemplateParams,
  TemplateSubmissionParams,
  Visibility,
  VoteDetail,
  VpcFundParams,
  WatchlistDetail,
  WorkTaskParams,
} from "./types";

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || "";

export const instance = axios.create({
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "x-csrftoken",
});

function withTokenGrantParams(path: string, redirectToPath: string) {
  // if BASE_URL is empty, redirect back to just a path
  // otherwise, include the current origin in the next_url for token_grant
  const origin = BASE_URL ? window.location.origin : "";
  const tokenGrantParams = new URLSearchParams({
    next: origin + redirectToPath,
  }).toString();
  const params = new URLSearchParams({
    next: `/api/users/token_grant?${tokenGrantParams}`,
  }).toString();

  return `${BASE_URL}${path}?${params}`;
}

export function emailSignInURL(redirectTo: string) {
  return withTokenGrantParams("/accounts/login", redirectTo);
}

export function microsoftSignInURL(redirectTo: string) {
  return withTokenGrantParams("/accounts/microsoft/login", redirectTo);
}

export function googleSignInURL(redirectTo: string) {
  return withTokenGrantParams("/accounts/google/login", redirectTo);
}

export const getter = (path: string) =>
  instance.get(BASE_URL + path).then((res) => res.data);

export async function getAuth() {
  return instance.get<types.AuthResponse>(`${BASE_URL}/api/users/auth`);
}

export async function getMe() {
  return instance.get<types.AuthResponse>(`${BASE_URL}/api/users/me`);
}

export async function getToken(token_grant: string) {
  return instance.get<types.AuthResponse>(
    `${BASE_URL}/api/users/token?token_grant=${token_grant}`
  );
}

export interface MicrosoftSignInParams {
  access_token: string;
}

export async function microsoftSignIn(params: MicrosoftSignInParams) {
  return instance.post<types.AuthResponse>(
    `${BASE_URL}/api/users/signin/microsoft`,
    params
  );
}

export interface PasswordSignInParams {
  username: string;
  password: string;
}

export async function signOut() {
  return instance.post(`${BASE_URL}/accounts/logout/`);
}

export async function passwordSignIn(params: PasswordSignInParams) {
  return instance.post<types.AuthResponse>(
    `${BASE_URL}/api/users/signin/password`,
    params
  );
}

export type SignInParams =
  | {
      provider: "microsoft";
      params: MicrosoftSignInParams;
    }
  | {
      provider: "password";
      params: PasswordSignInParams;
    };

export async function signIn(args: SignInParams) {
  switch (args.provider) {
    case "microsoft":
      return microsoftSignIn(args.params);
    case "password":
      return passwordSignIn(args.params);
  }
}

export async function updateUser(formData: FormData) {
  return instance.post(`${BASE_URL}/api/users/update`, formData);
}

export async function createPerson(data: CreateContactParams) {
  return instance.post(`${BASE_URL}/api/people_map/people`, data);
}

export async function updatePerson(formData: FormData, pk: number) {
  return instance.post(`${BASE_URL}/api/people_map/people/${pk}`, formData);
}

export async function getPeopleActivity(personId: number, data: BaseParams) {
  return instance
    .get(`${BASE_URL}/api/people_map/people/${personId}/activities`, {
      params: data,
    })
    .then((response) => response.data);
}

export async function getOrganizationsActivity(
  personId: number,
  data: BaseParams
) {
  return instance
    .get(`${BASE_URL}/api/people_map/organizations/${personId}/activities`, {
      params: data,
    })
    .then((response) => response.data.data);
}

export async function addConnection(pk: number) {
  return instance.post(
    `${BASE_URL}/api/people_map/people/${pk}/connections?connections=true`
  );
}

export async function deleteConnection(pk: number) {
  return instance.post(
    `${BASE_URL}/api/people_map/people/${pk}/connections?connections=false`
  );
}

export async function checkConnection(pk: number) {
  return instance.get(
    `${BASE_URL}/api/people_map/people/${pk}/connections/connectionstatus`
  );
}

export async function getPerson(pk: number) {
  return instance
    .get(`${BASE_URL}/api/people_map/people/${pk}`)
    .then((response) => response.data);
}

export async function deletePerson(pk: number) {
  return instance.delete(`${BASE_URL}/api/people_map/people/${pk}`);
}

export async function createOpportunity(data: CreateOpportunityParams) {
  return instance.post(`${BASE_URL}/api/people_map/opportunity/create`, data);
}

export async function createGTMProject(data: CreateGTMProjectParams) {
  return instance.post(`${BASE_URL}/api/people_map/bd`, data);
}

export async function deleteGTMProject(pk: number) {
  return instance.delete(`${BASE_URL}/api/people_map/bd/${pk}`);
}

export async function getGTMProjectDetail(pk: string) {
  return instance.get(`${BASE_URL}/api/people_map/bd/${pk}`);
}

export async function getGTMProject(projectId: number) {
  return instance
    .get(`${BASE_URL}/api/people_map/bd/${projectId}`)
    .then((response) => response.data);
}

export async function updateGTMProject(
  data: CreateGTMProjectParams,
  pk: number
) {
  return instance.post(`${BASE_URL}/api/people_map/bd/${pk}`, data);
}

export async function addRecommendationsToSavedLead(
  pk: number,
  data: (string | number)[]
) {
  return instance.post(`${BASE_URL}/api/people_map/bd/${pk}/leads`, {
    leads: data,
  });
}

export async function getRecommendations(
  organizationId: number,
  leadIds: string
) {
  return instance
    .get(
      `${BASE_URL}/api/people_map/bd/recommendations?organization=${organizationId}&seeds=${leadIds}`
    )
    .then((response) => response.data);
}

export async function getSavedLeads(projectId: number) {
  return instance
    .get(`${BASE_URL}/api/people_map/bd/${projectId}`)
    .then((response) => response.data);
}

export async function publishLeadsToOpportunity(
  pk: number,
  data: (string | number)[]
) {
  return instance.post(`${BASE_URL}/api/people_map/bd/${pk}/opportunities`, {
    organizations: data,
  });
}

export async function createGTMProjectOpportunity(
  projectId: number,
  opportunityId: number
) {
  return instance.post(
    `${BASE_URL}/api/people_map/bd/${projectId}/opportunities/${opportunityId}`
  );
}

export async function deleteGTMProjectOpportunity(
  projectId: number,
  opportunityId: number
) {
  return instance.delete(
    `${BASE_URL}/api/people_map/bd/${projectId}/opportunities/${opportunityId}`
  );
}

export async function updateGTMProjectDetail(
  data: CreateGTMProjectParams,
  pk: string | string[]
) {
  return instance.post(`${BASE_URL}/api/people_map/bd/${pk}`, data);
}

export async function updateGTMFeedback(feedback: string, pk: number) {
  return instance.post(
    `${BASE_URL}/api/people_map/bd/${pk}/feedback`,
    feedback
  );
}

export async function updateOpportunity(
  data: CreateOpportunityParams,
  pk: string
) {
  return instance.post(`${BASE_URL}/api/people_map/opportunity/${pk}`, data);
}

export async function createOpportunityTask(
  data: WorkTaskParams,
  pk: string | number
) {
  return instance.post(
    `${BASE_URL}/api/people_map/opportunity/${pk}/tasks`,
    data
  );
}

export async function createWorkTask(
  data: WorkTaskParams,
  pk: string | number
) {
  return instance.post(`${BASE_URL}/api/people_map/bd/${pk}/tasks`, data);
}

export async function deleteOpportunityTask(pk: string | number) {
  return instance.delete(`${BASE_URL}/api/people_map/tasks/${pk}`);
}

export async function completedOpportunityTask(
  data: WorkTaskParams,
  pk: string | number
) {
  return instance.post(`${BASE_URL}/api/people_map/tasks/${pk}`, data);
}

export async function createOpportunityAttachment(
  file: File,
  pk: string | number
) {
  const formData = new FormData();
  formData.append("file", file);
  return instance.post(
    `${BASE_URL}/api/people_map/opportunity/${pk}/attachment`,
    formData
  );
}

export async function deleteOpportunityAttachment(pk: string | number) {
  return instance.delete(`${BASE_URL}/api/people_map/attachment/${pk}`);
}

export async function deleteChatAttachment(pk: string | number) {
  return instance.delete(`${BASE_URL}/api/people_map/attachment/${pk}`);
}

export async function deleteOpportunity(pk: string) {
  return instance.delete(`${BASE_URL}/api/people_map/opportunity/${pk}`);
}

export async function updateSearchHistory(
  data: types.UpdateSearchHistoryParams
) {
  return instance.post(`${BASE_URL}/api/people_map/search_history`, data);
}

export async function getSearchHistory() {
  return instance
    .get<Success<types.SearchHistory[]>>(
      `${BASE_URL}/api/people_map/search_history`
    )
    .then((response) => response.data.data);
}

export async function requestOrganizationConnection(
  organization_id: number,
  data: types.RequestOrganizationConnectionParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/organizations/${organization_id}/request_connection`,
    data
  );
}

export async function requestIndividualConnection(
  person_id: number,
  data: types.RequestIndividualConnectionParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/people/${person_id}/request_introduction`,
    data
  );
}

export async function sendMeetingNoteEmail(
  meeting_note_id: number,
  data: types.MeetingNoteEmailParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/meeting_notes/${meeting_note_id}/send_email`,
    data
  );
}

export async function sendExternalEmail(data: types.ExternalEmailParams) {
  return instance.post(`${BASE_URL}/api/people_map/send_external_email`, data);
}

export async function enableOutlookSync() {
  return instance.post<types.EnableOutlookSyncResponse>(
    `${BASE_URL}/api/people_map/outlook_sync/enable`
  );
}

export async function disableOutlookSync() {
  return instance.post(`${BASE_URL}/api/people_map/outlook_sync/disable`);
}

export async function runOutlookSyncNow() {
  return instance.post(`${BASE_URL}/api/people_map/outlook_sync/run`);
}

export async function getMeetingNotes(data?: types.GetPortfolioParams) {
  return instance
    .get<Success<types.PaginatedList<types.MeetingNoteStub>>>(
      `${BASE_URL}/api/people_map/meeting_notes`,
      { params: data }
    )
    .then((response) => response.data);
}

export async function getAudioUrlMeetingNotes(data: URLSearchParams) {
  return instance.get(`${BASE_URL}/api/people_map/gcp_signed_url`, {
    params: data,
    headers: {
      "Cache-Control": "no-store, no-cache",
    },
  });
}

export async function meetingNotesTranscribe(
  pk: number,
  params: URLSearchParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/meeting_notes/${pk}/transcribe?${params.toString()}`
  );
}

export async function getTasks(data?: any) {
  return instance
    .get(`${BASE_URL}/api/people_map/tasks`, { params: data })
    .then((response) => response);
}

export async function getInvestor(uuid: string, investments_page: number = 0) {
  return instance
    .get(
      `${BASE_URL}/api/people_map/ninja/investors/${uuid}/investments?page=${investments_page}`
    )
    .then((response) => response);
}

export async function getOrganizationMeetingNotes(
  data?: types.GetOrganizationMeetingNotesParams,
  pk?: number
) {
  return instance
    .get<Success<types.PaginatedList<types.MeetingNote>>>(
      `${BASE_URL}/api/people_map/organizations/${pk}/meeting_notes`,
      {
        params: data,
      }
    )
    .then((response) => response.data);
}

export async function getPeopleMeetingNotes(
  data?: types.GetOrganizationMeetingNotesParams,
  pk?: number
) {
  return instance
    .get<Success<types.PaginatedList<types.MeetingNote>>>(
      `${BASE_URL}/api/people_map/people/${pk}/meeting_notes`,
      {
        params: data,
      }
    )
    .then((response) => response.data);
}

export async function createMeetingNote(
  data: types.CreateMeetingNoteParams,
  attachments: File[] | undefined
) {
  const formData = new FormData();
  formData.append("params", JSON.stringify(data));
  if (attachments) {
    attachments.map((file) => formData.append("attachments", file));
  }
  return instance.post(`${BASE_URL}/api/people_map/meeting_notes`, formData);
}

export async function createEmbeddings(pk: number) {
  return instance.post(
    `${BASE_URL}/api/people_map/meeting_notes/${pk}/create_embeddings`
  );
}

export async function deleteEmbeddings(pk: number) {
  return instance.delete(
    `${BASE_URL}/api/people_map/meeting_notes/${pk}/delete_embeddings`
  );
}

export async function updateMeetingNote(
  data: types.CreateMeetingNoteParams,
  attachments: File[] | undefined,
  pk: number
) {
  const formData = new FormData();
  formData.append("params", JSON.stringify(data));
  if (attachments) {
    attachments.map((file) => formData.append("attachments", file));
  }
  return instance.post(
    `${BASE_URL}/api/people_map/meeting_notes/${pk}`,
    formData
  );
}

export async function downloadFile(value: number) {
  return instance
    .get(`${BASE_URL}/api/people_map/attachment/${value}`, {
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
        "Content-Type": "application/pdf",
      },
    })
    .then((response) => {
      return response;
    });
}

export async function deleteMeetingNote(pk: number) {
  return instance.delete(`${BASE_URL}/api/people_map/meeting_notes/${pk}`);
}

export async function autocompletePeople(
  params: types.AutocompletePeopleParams
) {
  const query: Record<string, any> = {};
  Object.entries(params).forEach(([k, v]) => {
    if (v !== undefined) {
      query[k] = v;
    }
  });
  const qs = new URLSearchParams(query).toString();
  return instance
    .get<types.PersonAutocompleteResult[]>(
      `${BASE_URL}/api/people_map/autocomplete/people?${qs}`
    )
    .then((response) => response.data);
}

export async function autoCompletePeople(
  query: string,
  usersOnly?: boolean,
  useTag?: boolean,
  callBack?: (data: SuggestionDataItem[]) => void
) {
  const params: Record<string, any> = {};
  params["q"] = query;
  params["users_only"] = usersOnly ?? true;
  params["limit"] = 200;
  const qs = new URLSearchParams(params).toString();

  return instance
    .get<types.UserAutocompleteResult[]>(
      `${BASE_URL}/api/people_map/autocomplete/people?${qs}`
    )
    .then((response: any) => {
      return response.data.sort(
        (a: types.UserAutocompleteResult, b: types.UserAutocompleteResult) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }
      );
    })
    .then((result) => {
      //Transform data to id,display when use Tag
      if (useTag && callBack) {
        const data = result.map((e: types.AutocompleteResult) => {
          return {
            id: e.value,
            display: e.label,
          };
        });
        callBack(data);
      }
      return result;
    });
}

export async function autocompleteOrganizations(q: string) {
  return instance
    .get<types.OrganizationAutocompleteResult[]>(
      `${BASE_URL}/api/people_map/autocomplete/organizations?q=${encodeURIComponent(
        q
      )}&limit=50`
    )
    .then((response) => response.data);
}

export async function autocompleteCurrency(q: string) {
  return instance
    .get(`${BASE_URL}/api/portfolio/financial/currencies`)
    .then((response) => {
      return response.data.data
        .filter((currency: string) =>
          currency.toUpperCase().includes(q.toUpperCase())
        )
        .map((currency: string) => ({
          value: currency,
          label: currency,
        }));
    });
}

export async function getCurrency() {
  return instance
    .get(`${BASE_URL}/api/portfolio/financial/currencies`)
    .then((response) => response.data.data);
}

export async function autocompleteInvestors(q: string) {
  return instance
    .get<types.InvestorOrganization>(
      `${BASE_URL}/api/people_map/autocomplete/vertex_organizations?q=${encodeURIComponent(
        q
      )}`
    )
    .then((response) => response.data);
}

export async function autocompleteMeetingTypes(q: string) {
  return instance
    .get(
      `${BASE_URL}/api/people_map/autocomplete/meeting_type?q=${encodeURIComponent(
        q
      )}`
    )
    .then((response) => response.data);
}

export async function autocompleteAllInvestorOrganization() {
  return instance
    .get<types.InvestorOrganizationResult[]>(
      `${BASE_URL}/api/people_map/autocomplete/investor_organizations`
    )
    .then((response) => response.data);
}

export async function autocompleteFinancingStages(q: string) {
  return instance
    .get<types.AutocompleteResult[]>(
      `${BASE_URL}/api/people_map/autocomplete/financing_stages?q=${encodeURIComponent(
        q
      )}`
    )
    .then((response) => response.data);
}

export async function autocompleteVMF(q: string) {
  return instance
    .get<types.AutocompleteResult[]>(
      `${BASE_URL}/api/people_map/autocomplete/funds?q=${encodeURIComponent(q)}`
    )
    .then((response) => response.data);
}

export async function autocompleteNinjaOrganizations(q: string) {
  return instance
    .get<types.OrganizationAutocompleteResult[]>(
      `${BASE_URL}/api/people_map/autocomplete/external_organizations?q=${encodeURIComponent(
        q
      )}`
    )
    .then((response) => response.data);
}

export async function autocompleteSpecialities(q: string) {
  return instance
    .get<types.SpecialitiesAutocompleteResult[]>(
      `${BASE_URL}/api/people_map/autocomplete/specialities?q=${encodeURIComponent(
        q
      )}`
    )
    .then((response) => response.data);
}

export async function autocompleteInvestmentFunds(q: string) {
  return instance
    .get<types.InvestmentFundsAutocompleteResult[]>(
      `${BASE_URL}/api/people_map/autocomplete/investment_funds?q=${encodeURIComponent(
        q
      )}`
    )
    .then((response) => response.data);
}

export async function autocompleteTechnologies(q: string) {
  return instance
    .get<types.TechnologiesDetail[]>(
      `${BASE_URL}/api/people_map/autocomplete/technologies?q=${encodeURIComponent(
        q
      )}`
    )
    .then((response) => response.data);
}

export async function autocompleteMeetingNotesTags(q: string) {
  return instance
    .get<types.Tag[]>(
      `${BASE_URL}/api/people_map/autocomplete/meeting_note_tags?q=${encodeURIComponent(
        q
      )}&limit=100`
    )
    .then((response) => response.data);
}

export async function autocompleteIndustries(q: string) {
  return instance
    .get<types.TechnologiesDetail[]>(
      `${BASE_URL}/api/people_map/autocomplete/industries?q=${encodeURIComponent(
        q
      )}`
    )
    .then((response) => response.data);
}

export async function autocompleteUsers(q: string) {
  return instance
    .get<types.UserAutocompleteResult[]>(
      `${BASE_URL}/api/users/autocomplete/users?q=${encodeURIComponent(
        q
      )}&limit=100`
    )
    .then((response) => response.data);
}

export async function autocompleteUsersWithCallback(
  q: string,
  callBack: (data: SuggestionDataItem[]) => void
) {
  return instance
    .get<types.UserAutocompleteResult[]>(
      `${BASE_URL}/api/users/autocomplete/users?q=${encodeURIComponent(
        q
      )}&limit=100`
    )
    .then((response) => {
      const data = response.data.map((e: types.AutocompleteResult) => {
        return {
          id: e.value,
          display: e.label,
        };
      });
      callBack(data);
    });
}

export async function autocompleteUsersIncludingInactive(q: string) {
  return instance
    .get<types.UserAutocompleteResult[]>(
      `${BASE_URL}/api/users/autocomplete/users?q=${encodeURIComponent(
        q
      )}&limit=200&active=false`
    )
    .then((response) => response.data);
}

export async function autocompleteContacts(q: string) {
  return instance
    .get<types.UserAutocompleteResult[]>(
      `${BASE_URL}/api/people_map/search/people?q=${encodeURIComponent(
        q
      )}&limit=20`
    )
    .then((response) => {
      if (response.data) {
        return response.data.map((user: types.UserAutocompleteResult) => ({
          value: user.id,
          label: user.name,
        }));
      } else {
        return [];
      }
    });
}

export async function autocompleteGroups() {
  return instance
    .get<types.GroupAutocompleteResult[]>(
      `${BASE_URL}/api/users/autocomplete/groups`
    )
    .then((response) => response.data);
}

export async function autocompleteCountry(q: string) {
  return instance
    .get<types.CountryStub[]>(
      `${BASE_URL}/api/people_map/autocomplete/countries?q=${encodeURIComponent(
        q
      )}`
    )
    .then((response) => response.data);
}

export async function autocompleteAllGroups() {
  return instance
    .get<types.GroupAutocompleteResult[]>(
      `${BASE_URL}/api/users/autocomplete/allgroups`
    )
    .then((response) => response.data);
}

export async function autocompleteOpportunities(q: string) {
  return instance
    .get<OpportunityAutocompleteResult[]>(
      `${BASE_URL}/api/people_map/autocomplete/opportunities?q=${encodeURIComponent(
        q
      )}&limit=50`
    )
    .then((response) => response.data);
}

export async function importVertexConnection(
  organizationId: number,
  formData: FormData
) {
  return instance.post(
    `${BASE_URL}/api/people_map/organizations/${organizationId}/vertex_connections`,
    formData
  );
}

export async function importPeopleContacts(formData: FormData) {
  return instance.post(`${BASE_URL}/api/people_map/contacts`, formData);
}

export async function exportPeopleContacts(data: any) {
  return instance.post(
    `${BASE_URL}/api/people_map/advanced_search/people`,
    data
  );
}

export async function askVpcs(data: generativeAIVPCParams) {
  return instance
    .post<Success<types.AiVpc>>(`${BASE_URL}/api/people_map/ai/vpc`, data)
    .then((response) => response.data);
}

export async function askCorporate(data: generativeAICorporateParams) {
  return instance
    .post<Success<types.AiCorporate>>(
      `${BASE_URL}/api/people_map/ai/corporate`,
      data
    )
    .then((response) => response.data);
}

export async function askValChat(payload: any) {
  return instance
    .post<Success<types.ValChat>>(`${BASE_URL}/api/people_map/ai/chat`, payload)
    .then((response) => response.data);
}

export async function getAiGuide() {
  return instance
    .get(`${BASE_URL}/api/people_map/ai/guide`)
    .then((response) => response.data);
}

export async function getValChat(page: number, limit: number) {
  return instance
    .get<Success<types.PaginatedList<types.ValChat>>>(
      `${BASE_URL}/api/people_map/ai/chat?limit=${limit}&page=${page}`
    )
    .then((response) => response.data);
}

export async function getValModels() {
  return instance
    .get<Success<types.ValModel[]>>(`${BASE_URL}/api/people_map/ai/models`)
    .then((response) => response.data);
}

export async function getValChatById(valChatId: number) {
  return instance
    .get<Success<types.ValChat>>(
      `${BASE_URL}/api/people_map/ai/chat/${valChatId}`
    )
    .then((response) => response.data);
}

export async function askValChatById(valChatId: number, payload: any) {
  return instance
    .post<Success<types.ValChat>>(
      `${BASE_URL}/api/people_map/ai/chat/${valChatId}`,
      payload
    )
    .then((response) => response.data);
}

export async function deleteValChat(valChatId: number) {
  return instance.delete(`${BASE_URL}/api/people_map/ai/chat/${valChatId}`);
}

export interface CreateCommentParams {
  text: string;
  visibility?: "public" | "groups";
  groups?: number[];
  users?: number[];
}

export async function publishOrganizationComment(
  organization_id: string | number,
  data: CreateCommentParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/organizations/${organization_id}/comments`,
    data
  );
}

export async function getOrganizationComments(
  organization_id: string | number,
  date_range?: string,
  limit?: number
) {
  return instance
    .get(
      `${BASE_URL}/api/people_map/organizations/${organization_id}/allcomments?page=1&limit=${
        limit ?? 100
      }&date_range=${date_range ?? ""}`
    )
    .then((response) => response.data);
}

export async function publishOpportunityComment(
  opportunity_id: string | number,
  data: CreateCommentParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/opportunity/${opportunity_id}/comments`,
    data
  );
}

export async function getOpportunityComment(opportunity_id: string | number) {
  return instance.get(
    `${BASE_URL}/api/people_map/opportunity/${opportunity_id}/comments`
  );
}

export async function publishPeopleComment(
  people_id: string | number,
  data: CreateCommentParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/people/${people_id}/comments`,
    data
  );
}

export async function publishMeetingNoteComment(
  meeting_note_id: string | number,
  data: CreateCommentParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/meeting_notes/${meeting_note_id}/comments`,
    data
  );
}

export async function getMeetingNotesTags(q: string) {
  return instance
    .get(
      `${BASE_URL}/api/people_map/autocomplete/meeting_note_tags?q=${encodeURIComponent(
        q
      )}&limit=500`
    )
    .then((response) => {
      if (response.data) {
        return response.data.map((row: any) => ({
          id: row.value,
          label: row.label,
        }));
      } else {
        return [];
      }
    });
}

export async function postCollabThread(data: types.CollabThreadParams) {
  return instance.post(`${BASE_URL}/api/people_map/collab`, data);
}

export async function getAllCollabThread(data: URLSearchParams) {
  return instance.get(`${BASE_URL}/api/people_map/collab`, {
    params: data,
  });
}

export async function getCollabThread(threadId: number | string) {
  return instance.get(`${BASE_URL}/api/people_map/collab/${threadId}`);
}

export async function updateCollabThread(
  threadId: number,
  data: types.CollabThreadParams
) {
  return instance.post(`${BASE_URL}/api/people_map/collab/${threadId}`, data);
}

export async function deleteCollabThread(threadId: number) {
  return instance.delete(`${BASE_URL}/api/people_map/collab/${threadId}`);
}

export async function updateVoteCollabThread(commentId: number) {
  return instance.post(`${BASE_URL}/api/users/comments/${commentId}/vote`);
}

export async function rewardCollabThread(commentId: number) {
  return instance.post(`${BASE_URL}/api/users/comments/${commentId}/reward`);
}

export async function postCommentCollabThread(
  threadId: number,
  data: CreateCommentParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/collab/${threadId}/comments`,
    data
  );
}

export async function getCommentCollabThread(
  threadId: number,
  data: URLSearchParams
) {
  return instance.get(
    `${BASE_URL}/api/people_map/collab/${threadId}/comments`,
    {
      params: data,
    }
  );
}

export const toQuery = (params: object) => {
  return new URLSearchParams(
    Object.fromEntries(
      Object.entries(params)
        .filter(([k, v]) => !!v)
        .map(([k, v]) => [k, String(v)])
    )
  ).toString();
};

interface ListOrganizationCommentsParams {
  page?: number;
  limit?: number;
}

interface ListOrganizationOpportunitiesParams {
  page?: number;
  limit?: number;
}

export interface ListInvestmentRoundsParams {
  round_uuids: string;
}

export interface ListDealSourcingCommentsParams {
  page?: number;
  limit?: number;
}

export interface User {
  id: number;
  name: string;
  email: string;
  image_url: string;
}

export interface GenericObject {
  id: number;
  name: string;
  model: string;
}
export interface Comment {
  user: User;
  id: number;
  text: string;
  created_at: string;
  access_policy?: AccessPolicy;
  source?: GenericObject;
  votes?: VoteDetail[];
  reward?: boolean;
}

export interface ListCommentsResponse {
  items: Comment[];
  limit: number;
  total: number;
  page: number;
  pages: number;
}

export interface WatchlistResponse {
  items: WatchlistDetail[];
  limit: number;
  total: number;
  page: number;
  pages: number;
}

export interface ListOpportunityKey {
  endpoint: "/api/people_map/organizations/:id/opportunities";
  params: {
    id: number;
    page?: number;
    limit?: number;
  };
}

export interface ListOpportunityResponse {
  items: types.OpportunityDetail[];
  limit: number;
  total: number;
  page: number;
  pages: number;
}

export interface UpdateFunnelStageResponse {
  success: boolean;
  data: types.FunnelStageHistoryItem;
}

export interface DeleteFunnelStageResponse {
  success: boolean;
  data: any;
}

export async function listComments(endpoint: string) {
  return instance
    .get<ListCommentsResponse>(`${BASE_URL}${endpoint}`)
    .then((response) => response.data);
}

export async function publishComments(
  endpoint: string,
  data: CreateCommentParams
) {
  return instance.post(`${BASE_URL}${endpoint}`, data);
}

export async function deleteComment(comment_id: number) {
  return instance.delete(`${BASE_URL}/api/users/comments/${comment_id}`);
}

export async function editComment(comment: Comment) {
  const groups = comment.access_policy?.groups.map((grp) => grp.id);
  const users = comment.access_policy?.users.map((urp) => urp.id);
  return instance.post(`${BASE_URL}/api/users/comments/${comment.id}`, {
    text: comment.text,
    visibility: groups && groups.length > 0 ? "groups" : "public",
    groups: groups,
    users: users,
  });
}

export async function listOrganizationOpportunities(
  organization_id: number,
  params?: ListOrganizationOpportunitiesParams
) {
  const query = params ? `?${toQuery(params)}` : "";
  return instance
    .get<Success<ListOpportunityResponse>>(
      `${BASE_URL}/api/people_map/organizations/${organization_id}/opportunities${query}`
    )
    .then((response) => response.data.data);
}

export async function listInvestmentRounds(
  params?: ListInvestmentRoundsParams
) {
  const query = params ? `${toQuery(params)}` : "";
  return instance
    .get(`${BASE_URL}/api/people_map/ninja/investment_rounds?${query}`)
    .then((response) => response.data);
}

export async function getNinjaPatents(uuid: string, page: number = 0) {
  return instance
    .get(
      `${BASE_URL}/api/people_map/ninja/organizations/${uuid}/patents?page=${page}`
    )
    .then((response) => response);
}

export async function getNinjaLastNews(
  uuid: string,
  data: types.GetNinjaLastNewsParams
) {
  return instance
    .get(`${BASE_URL}/api/people_map/ninja/organizations/${uuid}/news`, {
      params: data,
    })
    .then((response) => response);
}

export async function deleteNinjaLastNews(uuid: string, newsUuid: string) {
  return instance.post(
    `${BASE_URL}/api/people_map/ninja/organizations/${uuid}/news/detach?news_uuid=${newsUuid}`
  );
}

export async function attachNinjaNews(uuid: string, newsUrl: string) {
  return instance.post(
    `${BASE_URL}/api/people_map/ninja/organizations/${uuid}/news/attach?news_url=${newsUrl}`
  );
}

export async function getNinjaFundingRounds(
  uuid: string,
  page: number = 0,
  investors: boolean = false
) {
  return instance
    .get(
      `${BASE_URL}/api/people_map/ninja/organizations/${uuid}/funding_rounds?page=${page}&investors=${investors}`
    )
    .then((response) => response);
}

export async function listMeetingNoteComments(
  meeting_note_id: number,
  params?: ListOrganizationCommentsParams
) {
  const query = params ? `?${toQuery(params)}` : "";
  return instance
    .get<ListCommentsResponse>(
      `${BASE_URL}/api/people_map/meeting_notes/${meeting_note_id}/comments${query}`
    )
    .then((response) => response.data);
}

export async function getWatchlist(params?: ListOrganizationCommentsParams) {
  const query = params ? `?${toQuery(params)}` : "";
  return instance
    .get<Success<WatchlistResponse>>(
      `${BASE_URL}/api/people_map/watchlist${query}`
    )
    .then((response) => response.data.data);
}

export async function createPortfolioOrganization(data: CreatePortfolioParams) {
  return instance.post(
    `${BASE_URL}/api/people_map/portfolio_organizations`,
    data
  );
}

export async function getPortfolioOrganization(
  pk: number,
  data: URLSearchParams
) {
  return instance.get(
    `${BASE_URL}/api/people_map/organizations/${pk}/portfolio`,
    {
      params: data,
    }
  );
}

export async function updatePortfolioOrganization(
  data: CreatePortfolioParams,
  pk: string
) {
  return instance.post(
    `${BASE_URL}/api/people_map/portfolio_organizations/${pk}`,
    data
  );
}

export async function deletePortfolioOrganization(pk: string) {
  return instance.delete(
    `${BASE_URL}/api/people_map/portfolio_organizations/${pk}`
  );
}

export async function deleteOrganization(
  pk: number,
  forceDelete: boolean = false
) {
  return instance.delete(
    `${BASE_URL}/api/people_map/organizations/${pk}?force_delete=${forceDelete}`
  );
}

export async function updateOrganization(formData: FormData, pk: number) {
  return instance.post(
    `${BASE_URL}/api/people_map/organizations/${pk}`,
    formData
  );
}

export async function updateIndexWebsite(organizationId: number) {
  return instance.post(
    `${BASE_URL}/api/people_map/organizations/${organizationId}/index_website`
  );
}

export function calculateVisibility(
  accessPolicy: AccessPolicy | null
): Visibility {
  if (!accessPolicy) {
    return "public";
  }
  if (accessPolicy.groups.length > 0 || accessPolicy.users.length > 1) {
    return "groups";
  }
  return "private";
}

export function accessPolicyDetail(accessPolicy: AccessPolicy | null) {
  if (accessPolicy) {
    let privacy = [];
    if (accessPolicy.groups.length !== 0) {
      privacy.push(accessPolicy.groups.map((group) => group.name).join(", "));
    }
    if (accessPolicy.groups.length !== 0 && accessPolicy.users.length !== 0) {
      privacy.push([", "]);
    }
    if (accessPolicy.users.length !== 0) {
      privacy.push(accessPolicy.users.map((user) => user.name).join(", "));
    }
    return privacy;
  } else {
    return "All Users";
  }
}

export async function updateFunnelStage(data: FunnelStageParams, pk: number) {
  return instance
    .post<UpdateFunnelStageResponse>(
      `${BASE_URL}/api/people_map/opportunity/${pk}/funnel`,
      data
    )
    .then((response) => response.data);
}

export async function deleteFunnelStage(pk: number) {
  return instance
    .delete<DeleteFunnelStageResponse>(
      `${BASE_URL}/api/people_map/opportunity/funnel/${pk}`
    )
    .then((response) => response.data);
}

export async function addOutlookBlacklist(data: EmailParams) {
  return instance
    .post<Success>(`${BASE_URL}/api/people_map/outlook_blacklist`, data)
    .then((response) => response.data);
}

export async function deleteOutlookBlacklist(pk: number) {
  return instance
    .delete<Success>(`${BASE_URL}/api/people_map/outlook_blacklist/${pk}`)
    .then((response) => response.data);
}

export async function createOrganisation(data: types.CreateOrganisationParams) {
  return instance.post(`${BASE_URL}/api/graph/edge`, data);
}

export async function deleteOrganination(pk: number) {
  return instance.delete(`${BASE_URL}/api/graph/edge/${pk}`);
}

export async function getOrganization(pk: number | string) {
  return instance.get(`${BASE_URL}/api/people_map/organizations/${pk}`);
}

export async function getVpc(pk: number | string) {
  return instance.get(`${BASE_URL}/api/people_map/organizations/${pk}/vpc`);
}

export async function getVmf(q: string) {
  return instance.get(
    `${BASE_URL}/api/people_map/autocomplete/funds?q=${encodeURIComponent(q)}`
  );
}

export async function getFs(q: string) {
  return instance.get(
    `${BASE_URL}/api/people_map/autocomplete/financing_stages?q=${encodeURIComponent(
      q
    )}`
  );
}

export async function getOpportunityActivityLog(
  opportunityId: number,
  data: types.GetActivityLogsParams
) {
  return instance
    .get<Success<types.PaginatedList<types.ActivityLog>>>(
      `${BASE_URL}/api/people_map/opportunity/${opportunityId}/history`,
      { params: data }
    )
    .then((response) => response.data);
}

export async function getActivityLog(data: types.GetActivityLogsParams) {
  return instance
    .get<Success<types.PaginatedList<types.ActivityLog>>>(
      `${BASE_URL}/api/people_map/history`,
      { params: data }
    )
    .then((response) => response.data);
}

export async function getFinanceActivityLog(data: types.GetActivityLogsParams) {
  return instance
    .get<Success<types.PaginatedList<types.ActivityLog>>>(
      `${BASE_URL}/api/portfolio/financial/history`,
      { params: data }
    )
    .then((response) => {
      return response.data;
    });
}

export async function getFinancialStatementActivityLog(
  portfolioId: number,
  data: types.GetActivityLogsParams
) {
  return instance
    .get<Success<types.PaginatedList<types.ActivityLog>>>(
      `${BASE_URL}/api/portfolio/${portfolioId}/financial/history`,
      { params: data }
    )
    .then((response) => {
      return response.data;
    });
}

export async function getOpportunities(data?: types.GetGroupOpportunityParams) {
  return instance
    .get<types.PaginatedList<types.OpportunityDetail>>(
      `${BASE_URL}/api/people_map/groups/opportunities`,
      { params: data }
    )
    .then((response) => response);
}

export async function getOrganizationFiles(
  organizationId: number,
  data?: types.GetGroupOpportunityParams
) {
  return instance
    .get<types.PaginatedList<types.FileAttachmentDetail>>(
      `${BASE_URL}/api/people_map/organizations/${organizationId}/attachment`,
      { params: data }
    )
    .then((response) => response);
}

export async function getEmployees(
  pk: number,
  data?: types.GetGroupOpportunityParams
) {
  return instance
    .get<Success<types.PaginatedList<types.Employee>>>(
      `${BASE_URL}/api/people_map/organizations/${pk}/employees`,
      { params: data }
    )
    .then((response) => response.data);
}

export async function getEmployeeConections(
  pk: number,
  data?: types.GetEmployeeConnectionsParams
) {
  return instance
    .get<Success<types.PaginatedList<types.Employee>>>(
      `${BASE_URL}/api/people_map/people/${pk}/connections`,
      { params: data }
    )
    .then((response) => response.data);
}

export async function getConnects(pk: number) {
  return instance
    .get(`${BASE_URL}/api/people_map/organizations/${pk}/vertex_connections`)
    .then((response) => response);
}

export async function getCorporates(data?: types.GetCorporatesParams) {
  return instance
    .get<Success<types.PaginatedList<types.OpportunityDetail>>>(
      `${BASE_URL}/api/people_map/corporates`,
      { params: data }
    )
    .then((response) => response.data);
}

export async function getGtmProject(data?: types.GetCorporatesParams) {
  return instance
    .get<types.PaginatedList<types.GTMProjectDetail>>(
      `${BASE_URL}/api/people_map/bd`,
      { params: data }
    )
    .then((response) => response);
}

export async function getPeople(data?: types.GetPeopleParams) {
  return instance
    .get(`${BASE_URL}/api/people_map/advanced_search/people`, { params: data })
    .then((response) => response.data);
}

export async function getOrganizations(data?: types.GetOrganizationsParams) {
  return instance.get(
    `${BASE_URL}/api/people_map/advanced_search/organizations`,
    {
      params: data,
    }
  );
}

export async function getLeads(data?: types.LeadDetail) {
  return instance.get(`${BASE_URL}/api/deal_sourcing/lead`, {
    params: data,
  });
}

export async function getLead(pk: number) {
  return instance
    .get(`${BASE_URL}/api/deal_sourcing/lead/${pk}`)
    .then((response) => response);
}

export async function updateLead(pk: string, data: types.UpdateLeadParams) {
  return instance.post(`${BASE_URL}/api/deal_sourcing/lead/${pk}`, data);
}

export async function listDealSourcingComments(
  lead_id: number,
  params?: ListDealSourcingCommentsParams
) {
  const query = params ? `?${toQuery(params)}` : "";
  return instance
    .get<ListCommentsResponse>(
      `${BASE_URL}/api/deal_sourcing/lead/${lead_id}/comments${query}`
    )
    .then((response) => response.data);
}

export async function publishDealSourcingComment(
  lead_id: string | number,
  data: CreateCommentParams
) {
  return instance.post(
    `${BASE_URL}/api/deal_sourcing/lead/${lead_id}/comments`,
    data
  );
}

export async function getConfigs(data: types.GetCoreSignalConfigAllParams) {
  return instance.get(`${BASE_URL}/api/deal_sourcing/config`, {
    params: data,
  });
}

export async function getPortfolio(data?: types.GetPortfolioParams) {
  return instance
    .get(`${BASE_URL}/api/people_map/portfolio_organizations`, { params: data })
    .then((response) => response);
}

export async function getPortfolioById(pk: number) {
  return instance
    .get(`${BASE_URL}/api/people_map/portfolio_organizations/${pk}`)
    .then((response) => response);
}

export async function getOpportunitiesDashboard(
  data?: types.GetGroupOpportunityParams
) {
  return instance
    .get<Success<types.DashboardOppportunityDetail>>(
      `${BASE_URL}/api/people_map/dashboard/opportunities`,
      { params: data }
    )
    .then((response) => response);
}

// export async function getValuesFromServer(column_fields: string) {
//   return instance
//     .get(`${BASE_URL}/api/people_map/tables/columns/${column_fields.toString()}`)
//     .then((response) => response.data.data);
// }

export async function getAutocompleteFunnelstages(opportunity_type: number) {
  return instance
    .get(`${BASE_URL}/api/people_map/autocomplete/funnel_stage`, {
      params: opportunity_type,
    })
    .then((response) => response.data);
}

export async function getOpportunityTypes() {
  return instance
    .get<types.OpportunityType[]>(
      `${BASE_URL}/api/people_map/opportunity/types`
    )
    .then((response) => response.data);
}

export async function getLinkedDeals(
  pk: string | number,
  data: Record<string, any>
) {
  const payload: Record<string, any> = {
    ...{
      limit: 100,
      permutate_organizations: true,
    },
    ...data,
  };
  return instance
    .get(
      `${BASE_URL}/api/people_map/organizations/${pk}/opportunities?${new URLSearchParams(
        payload
      )}`
    )
    .then((response) => response.data);
}

export async function saveTableState(data: types.CreateTableStateParams) {
  return instance.post(`${BASE_URL}/api/people_map/tables/save`, data);
}

export async function getAllTableStates() {
  return instance
    .get<Success<Array<types.TableStateFilter>>>(
      `${BASE_URL}/api/people_map/tables`
    )
    .then((response) => response.data);
}

export async function updateTableStateOrder(
  data: types.UpdateTableStateOrderParams
) {
  return instance.post(`${BASE_URL}/api/people_map/table/order`, data);
}

export async function createOutlookSubscription(keyword: string) {
  return instance.post(`${BASE_URL}/api/people_map/outlook_subscription`, {
    keyword: keyword,
  });
}

export async function createUpdateMetricValue(
  data: types.CreateMetricValueParams
) {
  return instance.post(
    `${BASE_URL}/api/portfolio/financial/metric_value_raw`,
    data
  );
}

export function getFinancialMetric(type: number) {
  return async function () {
    return instance
      .get<Success<types.FinancialMetricDetail[]>>(
        `${BASE_URL}/api/portfolio/financial/metric?type=${type}`
      )
      .then((response) => response.data.data);
  };
}

export async function createMetric(data: types.CreateMetricParams) {
  return instance.post(`${BASE_URL}/api/portfolio/financial/metric`, data);
}

export async function getFinancialTemplate(pk: number, type: number) {
  return instance.get(
    `${BASE_URL}/api/portfolio/${pk}/financial/template?type=${type}`
  );
}

export async function updateFinancialTemplate(
  data: types.UpdateMetricsTemplate
) {
  return instance.post(`${BASE_URL}/api/portfolio/financial/template`, data);
}

export async function updateMetric(pk: number, data: types.CreateMetricParams) {
  return instance.post(
    `${BASE_URL}/api/portfolio/financial/metric/${pk}`,
    data
  );
}
export async function createDealSourcingConfig(
  data: types.CoreSignalConfigUpdateParams
) {
  return instance.post(`${BASE_URL}/api/deal_sourcing/config`, data);
}

export async function updateDealSourcingConfig(
  pk: number,
  data: types.CoreSignalConfigUpdateParams
) {
  return instance.post(`${BASE_URL}/api/deal_sourcing/config/${pk}`, data);
}

export async function deleteMetric(pk: number) {
  return instance.delete(`${BASE_URL}/api/portfolio/financial/metric/${pk}`);
}

export async function createCapTable(formData: FormData) {
  return instance.post(
    `${BASE_URL}/api/portfolio/financial/captable`,
    formData
  );
}

export async function getCapTableById(id: number) {
  return instance
    .get(`${BASE_URL}/api/portfolio/${id}/financial/captable`)
    .then((res) => res.data.data as types.CapTableDetail[]);
}

export async function updateCapTableById(
  portfolio: number,
  formData: FormData
) {
  return instance.post(
    `${BASE_URL}/api/portfolio/financial/captable/${portfolio}`,
    formData
  );
}

export async function deleteCapTable(pk: number) {
  return instance.delete(`${BASE_URL}/api/portfolio/financial/captable/${pk}`);
}

export function fetchUsernames(
  query: string,
  callback: (data: SuggestionDataItem[]) => void
) {
  instance
    .get(`/api/users/autocomplete/users?q=${query}`)
    .then((response) => {
      if (response.data)
        return response.data.map((e: types.AutocompleteResult) => {
          return {
            id: e.value,
            display: e.label,
          };
        });
      else return [];
    })
    .then(callback);
}
export async function createQuarterlyPortfolioUpdate(
  data: types.UpdatesParams
) {
  return instance.post(`${BASE_URL}/api/portfolio/financial/update`, data);
}

export async function deleteQuarterlyPortfolioUpdate(pk: number) {
  return instance.delete(`${BASE_URL}/api/portfolio/financial/update/${pk}`);
}

export async function updateQuarterlyPortfolioUpdate(
  pk: number,
  data: types.UpdatesParams
) {
  return instance.post(
    `${BASE_URL}/api/portfolio/financial/update/${pk}`,
    data
  );
}

export async function getFinancialsUpdatesValues(pk: number) {
  return instance.get(`${BASE_URL}/api/portfolio/${pk}/financial/update`);
}

export async function getUpdateMetricValues(
  pk: number,
  params: Record<string, any>
) {
  return instance
    .get(
      `${BASE_URL}/api/portfolio/${pk}/financial/metric_values?${new URLSearchParams(
        params
      ).toString()}`
    )
    .then((response) => {
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        return [];
      }
    });
}

export async function getStatementTypes() {
  return instance
    .get(`${BASE_URL}/api/portfolio/financial/statement_types`)
    .then((response) => {
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        return [];
      }
    });
}

export async function uploadAttachmentFile(formData: FormData) {
  return instance.post(
    `${BASE_URL}/api/portfolio/financial/attachment`,
    formData
  );
}

export async function deleteAttachmentFile(pk: number) {
  return instance.delete(
    `${BASE_URL}/api/portfolio/financial/attachment/${pk}`
  );
}

export async function deleteGTM(pk: number) {
  return instance.delete(`${BASE_URL}/api/people_map/bd/${pk}`);
}

export async function deleteGTMNews(pk: number, news_pk: number) {
  return instance.delete(`${BASE_URL}/api/people_map/bd/${pk}/news/${news_pk}`);
}

export async function createGTMNews(pk: number, data: CreateGTMNewsParams) {
  return instance.post(`${BASE_URL}/api/people_map/bd/${pk}/news`, data);
}

export async function updateGTMNews(
  data: CreateGTMNewsParams,
  pk: number,
  news_pk: number
) {
  return instance.post(
    `${BASE_URL}/api/people_map/bd/${pk}/news/${news_pk}`,
    data
  );
}

export async function createGTMEvents(pk: number, data: CreateGTMEventsParams) {
  return instance.post(`${BASE_URL}/api/people_map/bd/${pk}/events`, data);
}

export async function deleteGTMEvents(pk: number, events_pk: number) {
  return instance.delete(
    `${BASE_URL}/api/people_map/bd/${pk}/events/${events_pk}`
  );
}

export async function updateGTMEvents(
  data: CreateGTMEventsParams,
  pk: number,
  events_pk: number
) {
  return instance.post(
    `${BASE_URL}/api/people_map/bd/${pk}/events/${events_pk}`,
    data
  );
}

export async function downloadCsv(url: string) {
  return instance.get(`${BASE_URL}${url}`);
}

export async function getHtmlTemplate(data: getOnePagerContentParams) {
  return instance.post(`${BASE_URL}/api/people_map/vpcs/editonepager`, data);
}

export async function downloadOnePager(
  html_str: types.downloadOnePagerParams,
  config: object = {}
) {
  return instance.post(
    `${BASE_URL}/api/people_map/vpcs/download`,
    html_str,
    config
  );
}

export async function getGTMPdfExport(pk: number, config: object = {}) {
  return instance.get(`${BASE_URL}/api/people_map/bd/${pk}/download`, config);
}

export async function createTableState(data: CreateTableStateParams) {
  return instance.post(`${BASE_URL}/api/people_map/table_state`, data);
}

export async function updateTableState(
  pk: number,
  data: CreateTableStateParams
) {
  return instance.post(`${BASE_URL}/api/people_map/table_state/${pk}`, data);
}

export async function deleteTableState(pk: number) {
  return instance.delete(`${BASE_URL}/api/people_map/table_state/${pk}`);
}

export async function getTableStateById(pk: number) {
  return instance.get(`${BASE_URL}/api/people_map/table_state/${pk}`);
}

export async function getTableState() {
  return instance.get(`${BASE_URL}/api/people_map/table_state`);
}

export async function getTableStateByName(tableName: string) {
  return instance.get(
    `${BASE_URL}/api/people_map/table_state?table_name=${tableName}`
  );
}

export async function getOpportunity(pk: number) {
  return instance.get(`${BASE_URL}/api/people_map/opportunity/${pk}`);
}

export async function followOrganization(pk: number) {
  return instance.post(
    `${BASE_URL}/api/people_map/organizations/${pk}/follow?follow=true`
  );
}

export async function unfollowOrganization(pk: number) {
  return instance.post(
    `${BASE_URL}/api/people_map/organizations/${pk}/follow?follow=false`
  );
}

export async function updateNotification(data: NotificationData) {
  return instance.post(`${BASE_URL}/api/people_map/settings`, data);
}

export async function generativeAI(data: generativeAIParams) {
  return instance.post(`${BASE_URL}/api/people_map/ai/comments`, data);
}

export async function getHistoryAI(pk: number, page: number) {
  return instance.get(
    `${BASE_URL}/api/people_map/organizations/${pk}/prompt_responses?page=${page}`
  );
}

export async function deleteAI(pk: number) {
  return instance.delete(`${BASE_URL}/api/people_map/prompt_response/${pk}`);
}

export async function getCompletions(prompt: string) {
  return instance.post<types.EditorResponse>(
    `${BASE_URL}/api/people_map/ai/askval`,
    { prompt }
  );
}

export async function mergeOrganizations(
  pk: number,
  data: types.MergeOrganizationsParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/organizations/${pk}/merge`,
    data
  );
}

export async function mergePersons(pk: number, data: types.MergePersonsParams) {
  return instance.post(`${BASE_URL}/api/people_map/people/${pk}/merge`, data);
}

export async function mergeOpportunities(
  pk: number,
  data: types.MergeOpportunitiesParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/opportunity/${pk}/merge`,
    data
  );
}

export async function getFinancialUpdateSummary(
  start_quarter: string,
  end_quarter: string
) {
  return instance
    .get(
      `${BASE_URL}/api/portfolio/financial/all?start_quarter=${start_quarter}&end_quarter=${end_quarter}`
    )
    .then((response) => {
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        return [];
      }
    });
}

export async function updateFund(data: InvestmentFundParams, pk: number) {
  return instance.post(
    `${BASE_URL}/api/people_map/investment_funds/${pk}`,
    data
  );
}

export async function createFund(data: InvestmentFundParams) {
  return instance.post(`${BASE_URL}/api/people_map/investment_funds`, data);
}

export async function deleteFund(fundId: number) {
  return instance.delete(
    `${BASE_URL}/api/people_map/investment_funds/${fundId}`
  );
}

export async function getApolloPerson(queryParams: string) {
  return instance
    .get<Success<types.ApolloPersonDetail>>(
      `${BASE_URL}/api/people_map/apollo?${queryParams}`
    )
    .then((resp) => resp.data);
}

export async function getApolloOrganization(data: URLSearchParams) {
  return instance
    .get(`${BASE_URL}/api/people_map/apollo/organization`, {
      params: data,
    })
    .then((resp) => resp.data);
}

export async function deleteDealSourcingConfig(configId: number) {
  return instance.delete(`${BASE_URL}/api/deal_sourcing/config/${configId}`);
}

export async function getTemplates() {
  return instance
    .get(`${BASE_URL}/api/checklist/template`)
    .then((response) => response);
}

export async function getAllResponseForTemplate(
  data: types.ResponseTemplateParams,
  pk: number
) {
  return instance
    .get(`${BASE_URL}/api/checklist/template/${pk}/response`, { params: data })
    .then((response) => response);
}

export async function getAllSubmissionDatesForTemplates(
  pk: number,
  data: types.ResponseTemplateParams
) {
  return instance
    .get(`${BASE_URL}/api/checklist/template/${pk}/submission_dates`, {
      params: data,
    })
    .then((response) => response);
}

export async function createTemplate(data: TemplateParams) {
  return instance.post(`${BASE_URL}/api/checklist/template`, data);
}

export async function updateTemplate(templateId: number, data: TemplateParams) {
  return instance.post(
    `${BASE_URL}/api/checklist/template/${templateId}`,
    data
  );
}

export async function deleteTemplate(templateId: number) {
  return instance.delete(`${BASE_URL}/api/checklist/template/${templateId}`);
}

export async function getTemplateById(templateId: number) {
  return instance
    .get(`${BASE_URL}/api/checklist/template/${templateId}`)
    .then((response) => response.data);
}

export async function getTemplate(data: URLSearchParams) {
  return instance
    .get(`${BASE_URL}/api/checklist/template`, {
      params: data,
    })
    .then((response) => response.data);
}

export async function createBlock(data: TemplateBlockParams) {
  return instance.post(`${BASE_URL}/api/checklist/block`, data);
}

export async function updateBlock(blockId: number, data: TemplateBlockParams) {
  return instance.post(`${BASE_URL}/api/checklist/block/${blockId}`, data);
}

export async function deleteBlock(blockId: number) {
  return instance.delete(`${BASE_URL}/api/checklist/block/${blockId}`);
}

export async function deleteBlockAttachment(blockId: number) {
  return instance.delete(
    `${BASE_URL}/api/checklist/response/${blockId}/attachments`
  );
}

export async function createSubmission(data: TemplateSubmissionParams) {
  return instance.post(`${BASE_URL}/api/checklist/submission`, data);
}

export async function createResponse(data: FormData) {
  return instance.post(`${BASE_URL}/api/checklist/response`, data);
}

export async function updateResponse(responseId: number, data: FormData) {
  return instance.post(
    `${BASE_URL}/api/checklist/response/${responseId}`,
    data
  );
}

export async function getResponseFromSubmission(submissionId: number) {
  return instance
    .get(`${BASE_URL}/api/checklist/submission/${submissionId}/response`)
    .then((response) => response.data);
}

export async function getSubmissions(data: URLSearchParams) {
  return instance
    .get(`${BASE_URL}/api/checklist/submission`, {
      params: data,
    })
    .then((response) => response.data);
}

export async function getSubmissionsById(submissionId: number) {
  return instance
    .get(`${BASE_URL}/api/checklist/submission/${submissionId}`)
    .then((response) => response.data);
}

export async function updateSubmission(submissionId: number, data: any) {
  return instance.post(
    `${BASE_URL}/api/checklist/submission/${submissionId}`,
    data
  );
}

export async function deleteSubmission(submissionId: number) {
  return instance.delete(
    `${BASE_URL}/api/checklist/submission/${submissionId}`
  );
}

export async function cloneSubmission(submissionId: number, data: any) {
  return instance.post(
    `${BASE_URL}/api/checklist/submission/${submissionId}/clone`,
    data
  );
}

export async function getExchangeRate(source: string, target: string) {
  return instance
    .get(
      `${BASE_URL}/api/portfolio/financial/rates?source=${source}&target=${target}`
    )
    .then((response) => response.data);
}

export async function getNinjaInvestorCommitments(
  uuid: string,
  commitmentType: "commitment" | "contributor"
) {
  return instance
    .get<Success<types.NinjaCommitmentDetails[]>>(
      `${BASE_URL}/api/people_map/ninja/investors/${uuid}/commitments?commitment_type=${commitmentType}`
    )
    .then((response) => response.data);
}

export async function getNinjaFundCommitments(
  uuid: string,
  commitmentType: "commitment" | "contributor"
) {
  return instance
    .get<Success<types.NinjaCommitmentDetails[]>>(
      `${BASE_URL}/api/people_map/ninja/fund/${uuid}/commitments?commitment_type=${commitmentType}`
    )
    .then((response) => response.data);
}

export async function createAgentPersona(data: types.AgentPersonaParams) {
  return instance.post(`${BASE_URL}/api/people_map/agent_persona`, data);
}

export async function getAllAgentPersona() {
  return instance.get(`${BASE_URL}/api/people_map/agent_persona`);
}

export async function getAgentPersonaById(pk: number) {
  return instance.get(`${BASE_URL}/api/people_map/agent_persona/${pk}`);
}

export async function deleteAgentPersona(pk: number) {
  return instance.delete(`${BASE_URL}/api/people_map/agent_persona/${pk}`);
}

export async function pinAgent(pk: number, pin: boolean) {
  return instance.post(
    `${BASE_URL}/api/people_map/agent_persona/${pk}/pin?pin=${pin}`
  );
}

export async function updateAgentPersona(
  pk: number,
  data: types.AgentPersonaParams
) {
  return instance.post(`${BASE_URL}/api/people_map/agent_persona/${pk}`, data);
}

export async function createTemplatePrompt(
  pk: number,
  data: types.TemplatePromptParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/agent_persona/${pk}/template_prompt`,
    data
  );
}

export async function getTemplatePrompt(pk: number) {
  return instance.get(`${BASE_URL}/api/people_map/template_prompt/${pk}`);
}

export async function getAllTemplatePrompt(pk: number) {
  return instance.get(
    `${BASE_URL}/api/people_map/agent_persona/${pk}/template_prompts`
  );
}

export async function updateTemplatePrompt(
  pk: number,
  data: types.TemplatePromptParams
) {
  return instance.post(
    `${BASE_URL}/api/people_map/template_prompt/${pk}`,
    data
  );
}

export async function deleteTemplatePrompt(pk: number) {
  return instance.delete(`${BASE_URL}/api/people_map/template_prompt/${pk}`);
}

export async function getPersonaTools() {
  return instance.get(`${BASE_URL}/api/people_map/agent_persona/tools`);
}

export async function createVpcFund(data: VpcFundParams) {
  return instance
    .post(`${BASE_URL}/api/people_map/funds`, data)
    .then((response) => response.data);
}

export async function updatedVpcFund(data: VpcFundParams, pk: string | number) {
  return instance
    .post(`${BASE_URL}/api/people_map/funds/${pk}`, data)
    .then((response) => response.data);
}

export async function deleteVpcFund(fundId: number) {
  return instance.delete(`${BASE_URL}/api/people_map/funds/${fundId}`);
}

export async function createVertexInvestor(data: types.OrganizationInvestment) {
  return instance
    .post(`${BASE_URL}/api/people_map/organization_investments`, data)
    .then((response) => response.data);
}

export async function updateVertexInvestor(
  pk: number,
  data: types.OrganizationInvestment
) {
  return instance
    .post(`${BASE_URL}/api/people_map/organization_investments/${pk}`, data)
    .then((response) => response.data);
}

export async function deleteVertexInvestor(pk: number) {
  return instance.delete(
    `${BASE_URL}/api/people_map/organization_investments/${pk}`
  );
}

export async function getOrganizationDetails(pk: number) {
  return instance
    .get(
      `${BASE_URL}/api/people_map/organizations/${pk}?update_from_ninja=False`
    )
    .then((response) => response.data);
}
