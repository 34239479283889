import { Dialog, Popover, Transition } from "@headlessui/react";
import {
  ArchiveBoxIcon,
  ArrowTopRightOnSquareIcon,
  CheckIcon,
  ChevronRightIcon,
  ClipboardDocumentIcon,
  DocumentTextIcon,
  EllipsisHorizontalIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
  PencilIcon,
  PlusIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import DOMPurify from "dompurify";
import Link from "next/link";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Dropzone from "react-dropzone";
import { useMediaQuery } from "react-responsive";
import {
  askValChat,
  askValChatById,
  deleteChatAttachment,
  deleteValChat,
  getAllTemplatePrompt,
  getValChatById,
  getValModels,
} from "../../api";
import {
  AgentPersonaStub,
  MeetingNote,
  Option,
  PydanticError,
  SearchResultOrganization,
  TemplatePrompt,
  ValChat,
} from "../../api/types";
import CreateEditMeetingNote from "../../pages/meeting_notes/create";
import { useCurrentUser } from "../AuthProvider";
import Avatar from "../Avatar";
import { ConfirmationModal } from "../ConfirmationModal";
import ErrorModal from "../ErrorModal";
import { Llama } from "../Llama";
import NewModal from "../NewModal";
import { PopoverComponent } from "../PopoverComponent";
import ResizableComponent from "../ResizableComponent";
import { parseAxiosError } from "../utils";
import { viewAndDownloadAttachment } from "../Utils/fileUtils";
import { ShowSnackBar } from "../Utils/supportMessage";
import { AgentsView } from "./AgentsView";
import {
  CHAT_VAL_AGENT_KEY,
  CHAT_VAL_HISTORY_KEY,
  COPY_STATUS_DELAY,
  MarkdownContent,
  replaceDoubleBraces,
  SHOW_NOTIFICATION_DELAY,
  VAL_HISTORY_STATUS_KEY,
  VAL_STATUS_KEY,
  VAL_WIDTH_KEY,
} from "./ChatbotUtils";
import { HistoryView } from "./HistoryView";
import { OrganizationDropdown } from "./OrganizationDropdown";
import { PromptsGuideView } from "./PromptsGuideView";

type ChatBotViewProps = {
  defaultWidth?: number;
};

const getWidthValStatus: () => number = () => {
  const screenWidth = window.innerWidth;
  const width = Number(window.sessionStorage.getItem(VAL_WIDTH_KEY));
  const status = width && width > 0 ? width * screenWidth : 800;
  return status;
};

const getExpandValStatus = (key: string): boolean => {
  const status = window.sessionStorage.getItem(key) === "true";
  return status;
};

const setExpandValStatus = (key: string, status: boolean) => {
  window.sessionStorage.setItem(key, status.toString());
  window.dispatchEvent(new Event("storage"));
};

export default function ChatbotView({ defaultWidth }: ChatBotViewProps) {
  const { user: currentUser } = useCurrentUser();
  const messagesContainer = useRef<HTMLDivElement>(null);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [chatHistory, setChatHistory] = useState<ValChat[]>([]);
  const [historyPage, setHistoryPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);

  const [messages, setMessages] = useState<any[]>([]);
  const [currentConservationId, setCurrentConservationId] = useState<
    number | null
  >(null);

  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const textAreaRef = useRef<HTMLDivElement>(null);
  const lastQuestionRef = useRef<HTMLDivElement>(null);

  const rightContainerRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [showConfirmDeletion, setShowConfirmDeletion] =
    useState<boolean>(false);
  const [deletedHistory, setDeletedHistory] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [historyId, setHistoryId] = useState<number>(0);

  const [showConfirmDeletionAttachment, setShowConfirmDeletionAttachment] =
    useState<boolean>(false);
  const [deletedAttachment, setDeletedAttachment] = useState<boolean>(false);
  const [deletingAttachment, setDeletingAttachment] = useState<boolean>(false);
  const [attachmentId, setAttachmentId] = useState<number | string>(0);

  const [showNotification, setShowNotification] = useState(false);
  const [valModels, setValModels] = useState<Option[]>([]);
  const [modelSelected, setModelSelected] = useState<Option | null>();

  //Agents
  const [showPromptGuide, setShowPromptGuide] = useState<boolean>(false);
  const [agentSelected, setAgentSelected] = useState<AgentPersonaStub>();
  const [prompts, setPrompts] = useState<TemplatePrompt[]>([]);

  const [promptSelecting, setPromptSelecting] = useState<TemplatePrompt>();

  const [chatAttachments, setChatAttachments] = useState<Option[]>([]);
  const [attachmentFiles, setAttachmentFiles] = useState<File[]>([]);

  const [chatText, setChatText] = useState<string>("");
  const [chatHtml, setChatHtml] = useState<string>("");

  const [showChatTooltip, setShowChatTooltip] = useState<boolean>(false);
  const [showHistoryTooltip, setHistoryTooltip] = useState<boolean>(false);
  const [hideHistoryTooltip, setHideHistoryTooltip] = useState<boolean>(false);
  const [width, setWidth] = useState(getWidthValStatus());
  const [currentKey, setCurrentKey] = useState<any>();

  const chatPanel = useRef<HTMLDivElement>(null);

  const [expandHistory, setExpandHistory] = useState<boolean>(
    getExpandValStatus(VAL_HISTORY_STATUS_KEY)
  );
  const [showAskVal, setShowAskVal] = useState<boolean>(
    getExpandValStatus(VAL_STATUS_KEY)
  );

  const [organizationsSelected, setOrganizationsSelected] = useState<
    SearchResultOrganization[]
  >([]);

  const isTabletOrDesktop = useMediaQuery({
    minWidth: 769,
  });

  // Update showAskVal state when sessionStorage changes
  useEffect(() => {
    const handleStorageChange = () => {
      setShowAskVal(getExpandValStatus(VAL_STATUS_KEY));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useMemo(() => {
    if (showAskVal) {
      getValModels()
        .then((res: any) => {
          const models: Option[] = [];
          res.data.forEach((model: any) => {
            const temp = {
              value: model.model_name,
              label: model.name,
            };
            models.push(temp);
          });
          setValModels(models);

          //Init model
          const lastModel = localStorage.getItem("chatbotSelectedModel") ?? "";
          if (lastModel.length > 0) {
            const prevModel = JSON.parse(lastModel);
            if (
              res.data.filter(
                (model: any) => model.model_name === prevModel.value
              ).length > 0
            ) {
              setModelSelected(JSON.parse(lastModel));
            }
          } else {
            setModelSelected(models[0]);
          }
        })
        .catch((err: AxiosError) => {
          setErrorModal(true);
          setErrorMessage(parseAxiosError(err));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAskVal]);

  useEffect(() => {
    if (historyId > 0) {
      loadHistory(historyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyId]);

  const initAgentFromLastChoice = () => {
    const lastHistory = localStorage.getItem(CHAT_VAL_HISTORY_KEY) ?? "";
    if (lastHistory && lastHistory.length > 0) {
      setHistoryId(Number(lastHistory));
    } else {
      const lastAgent = localStorage.getItem(CHAT_VAL_AGENT_KEY) ?? "";
      if (lastAgent.length > 0) {
        setAgentSelected(JSON.parse(lastAgent));
        setShowPromptGuide(true);
      }
    }
  };

  useEffect(() => {
    initAgentFromLastChoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAskVal]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showNotification) {
      timeout = setTimeout(
        () => setShowNotification(false),
        SHOW_NOTIFICATION_DELAY
      );
    }
    return () => clearTimeout(timeout);
  }, [showNotification]);

  const askChatbot = (chatText: string, overwrite: boolean = false) => {
    if (chatText.length > 0 && agentSelected) {
      const formData = new FormData();

      let data = {
        text: chatText,
        llm_model_name: modelSelected?.value ?? "",
        agent_persona_id: agentSelected.id,
        organizations:
          organizationsSelected.length > 0
            ? organizationsSelected.map((org) => org.id)
            : [],
      };

      if (attachmentFiles && attachmentFiles.length > 0) {
        attachmentFiles.map((file) => formData.append("attachments", file));
      } else {
        formData.append("attachments", "");
      }
      setAttachmentFiles([]);

      if (overwrite) {
        data = { ...data, ...{ overwrite: overwrite } };
        setMessages([...messages.slice(0, messages.length - 2), chatText]);
      } else {
        setMessages([...messages, chatText]);
      }

      setIsReplying(true);

      formData.append("params", JSON.stringify(data));

      const apiCaller = currentConservationId
        ? askValChatById(currentConservationId, formData)
        : askValChat(formData);

      apiCaller
        .then((response: any) => {
          if (response.data) {
            const answer = response.data as ValChat;

            if (overwrite) {
              setMessages([
                ...messages.slice(0, messages.length - 2),
                ...[
                  chatText,
                  {
                    last_response:
                      answer.messages[answer.messages.length - 1].content,
                  },
                ],
              ]);
            } else {
              setMessages([
                ...messages,
                ...[
                  chatText,
                  {
                    last_response:
                      answer.messages[answer.messages.length - 1].content,
                  },
                ],
              ]);
            }
            setCurrentConservationId(response.data.id);

            //Update History
            if (chatHistory.length > 0) {
              if (chatHistory.map((chat) => chat.id).includes(answer.id)) {
                setChatHistory([...[], ...chatHistory]);
              } else {
                setChatHistory([...[answer], ...chatHistory]);
              }
            } else {
              setChatHistory([answer]);
            }
            setChatAttachments(answer.attachments);
          }
        })
        .catch((error: any) => {
          if (error.response.data.detail) {
            const errors: PydanticError = error.response.data;
            const errorDetails = errors.detail?.map(
              (v) => `${v.loc[2]}: ${v.msg}`
            );
            setMessages([...messages, ...[chatText, errorDetails]]);
          } else {
            setMessages([...messages, ...[chatText, [error.toString()]]]);
          }
        })
        .finally(() => {
          setIsReplying(false);
        });
    }
  };

  useEffect(() => {
    if (messagesContainer.current) {
      messagesContainer.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    // eslint-disable-next-line
  }, [messages]);

  const Loading = () =>
    useMemo(() => {
      return (
        <div className="max-w-sm my-2  w-fit mr-auto p-2 whitespace-pre-line">
          <div className="flex flex-row mt-2 gap-x-0.5">
            <div className="bg-blue-100 w-2 h-2 rounded-full animate-bounce animate-[bounce_1s_infinite_100ms]"></div>
            <div className="bg-blue-200 w-2 h-2 rounded-full animate-bounce animate-[bounce_1s_infinite_300ms]"></div>
            <div className="bg-blue-300 w-2 h-2 rounded-full animate-bounce animate-[bounce_1s_infinite_500ms]"></div>
          </div>
        </div>
      );
      // eslint-disable-next-line
    }, []);

  const createNewConversation = () => {
    setMessages([]);
    setHistoryId(0);
    setCurrentConservationId(null);
    setChatAttachments([]);
    setAttachmentFiles([]);
    setPrompts([]);
    setPromptSelecting(undefined);
    resetChat();
  };

  const resetChat = () => {
    if (textAreaRef.current) {
      textAreaRef.current.childNodes.forEach((node) => {
        node.remove();
      });
    }
    setChatText("");
    setChatHtml("");
  };

  const loadHistory = (conservationId: number) => {
    if (showAskVal && conservationId > 0) {
      getValChatById(conservationId).then((response: any) => {
        if (response.data) {
          const history = response.data as ValChat;
          let messages: any[] = [];
          history.messages.forEach((message) => {
            messages.push(
              message.type === "human"
                ? message.content
                : { last_response: message.content }
            );
          });
          setAgentSelected(history.agent_persona);
          setMessages(messages);
          setChatAttachments(history.attachments);
          setCurrentConservationId(history.id);
          if (history.agent_persona) {
            getAllTemplatePrompt(history.agent_persona.id)
              .then((response) => {
                setPrompts(response.data.data);
              })
              .catch((error) => console.log(error));
            localStorage.setItem(
              CHAT_VAL_AGENT_KEY,
              JSON.stringify(history.agent_persona)
            );
          }
        }
      });
    }
  };

  const deleteHistory = (historyId: number) => {
    setDeleting(true);
    deleteValChat(historyId)
      .then((response) => {
        if (response.status === 200) {
          setChatHistory(
            chatHistory.filter((history: ValChat) => history.id !== historyId)
          );
          setDeletedHistory(true);
          setDeleting(false);
          createNewConversation();
        }
      })
      .catch((err: AxiosError) => {
        setErrorModal(true);
        setErrorMessage(parseAxiosError(err));
      });
  };

  const deleteAttachment = (attachmentId: number | string) => {
    setDeletingAttachment(true);
    deleteChatAttachment(attachmentId)
      .then((response) => {
        if (response.status === 200) {
          setChatAttachments(
            chatAttachments.filter((file) => file.value !== attachmentId)
          );
          setDeletedAttachment(true);
          setDeletingAttachment(false);
        }
      })
      .catch((err: AxiosError) => {
        setErrorModal(true);
        setErrorMessage(parseAxiosError(err));
      });
  };

  const selectPrompt = (prompt: TemplatePrompt) => {
    const promptContent = replaceDoubleBraces(prompt.prompt);
    setPromptSelecting(prompt);
    setChatText(prompt.prompt);
    setChatHtml(promptContent);
  };

  const onPromptSelected = (prompt: TemplatePrompt) => {
    selectPrompt(prompt);
    textAreaRef.current?.focus();
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current?.focus();
    }
    // eslint-disable-next-line
  }, [messages]);

  const Tooltip = (value: string) => {
    return (
      <div className="text-3xs text-white absolute bg-gray-900 rounded-md shadow-md text-xs py-1 px-2 w-max z-10">
        {value}
      </div>
    );
  };

  const onGetPrompts = (prompts: TemplatePrompt[]) => {
    setPrompts(prompts);
  };

  const copyToClipboard = (code: any, setCopyStatus: any) => {
    const textarea = document.createElement("textarea");
    textarea.value = code;
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopyStatus("Copied!");
    setTimeout(() => setCopyStatus(""), COPY_STATUS_DELAY);
    setCurrentKey(code);
    ShowSnackBar("Copied!");
  };

  const [copyStatus, setCopyStatus] = useState("");
  const [copyLastMsgStatus, setCopyLastMsgStatus] = useState("");

  const textBoxRef = useRef<HTMLDivElement>(null);
  const [beginResize, setBeginResize] = useState<boolean>(false);

  const [offSetY, setOffSetY] = useState<number>(0);
  const [offSetHeight, setOffSetHeight] = useState<number>(0);

  const [boxHeight, setBoxHeight] = useState<number>(110);

  const [showCreateNoteModal, setShowCreateNoteModal] =
    useState<boolean>(false);
  const [showCreateNoteSuccessModal, setShowCreateNoteSuccessModal] =
    useState<boolean>(false);
  const [meetingNote, setMeetingNote] = useState<MeetingNote | undefined>(
    undefined
  );

  type QuestionComponentProps = {
    index: number;
    msg: any;
    lastQuestion: boolean;
  };

  const QuestionComponent = ({
    msg,
    index,
    lastQuestion,
  }: QuestionComponentProps) => {
    const [editing, setEditing] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    useEffect(() => {
      setMessage(msg);
    }, [msg]);

    return (
      <div key={`${index}_${msg}`}>
        {msg.length > 0 &&
          (editing ? (
            <div className="group flex gap-x-2 mt-2 items-start">
              <div className="flex items-center justify-center mt-1.5">
                <Avatar
                  name={currentUser.name}
                  size={28}
                  url={currentUser.image_url}
                />
              </div>
              <div className="flex flex-col gap-y-2 w-full">
                <div className="flex flex-col justify-center items-center border-2 py-2 px-1 border-blue-menu rounded-lg mr-10">
                  <div
                    ref={lastQuestionRef}
                    suppressContentEditableWarning
                    contentEditable={true}
                    onPaste={(event: any) => {
                      event.preventDefault();
                      let paste = event.clipboardData.getData("text").trim();
                      const selection = window.getSelection();
                      if (!selection?.rangeCount) return;
                      selection.deleteFromDocument();
                      selection
                        .getRangeAt(0)
                        .insertNode(document.createTextNode(paste));
                      selection.collapseToEnd();
                    }}
                    onCut={(event: any) => {
                      event.preventDefault();
                      let paste = event.clipboardData.getData("text").trim();
                      const selection = window.getSelection();
                      if (!selection?.rangeCount) return;
                      selection.deleteFromDocument();
                      selection
                        .getRangeAt(0)
                        .insertNode(document.createTextNode(paste));
                      selection.collapseToEnd();
                    }}
                    onKeyDown={(event: any) => {
                      if (event.keyCode === 13) {
                        if (event.shiftKey) {
                          event.stopPropagation();
                        } else {
                          if (
                            event.target.innerText.trim().length > 0 &&
                            event.target.innerText.trim() !== msg &&
                            !isReplying
                          ) {
                            event.preventDefault();
                            askChatbot(event.target.innerText.trim(), true);
                            event.target.innerText = "";
                          }
                        }
                      }
                    }}
                    onKeyUp={(event: any) => {
                      if (event.keyCode !== 13) {
                        const btnUpdateQuestion = document.getElementById(
                          "btn-update-question"
                        );
                        if (btnUpdateQuestion) {
                          if (
                            event.target.textContent.trim().length > 0 &&
                            event.target.textContent.trim() !== msg
                          ) {
                            btnUpdateQuestion.classList.add("bg-blue-menu");
                            btnUpdateQuestion.classList.add("text-white");
                            btnUpdateQuestion.classList.remove("bg-gray-300");
                            btnUpdateQuestion.classList.remove("text-gray-500");
                          } else {
                            btnUpdateQuestion.classList.add("bg-gray-300");
                            btnUpdateQuestion.classList.add("text-gray-500");
                            btnUpdateQuestion.classList.remove("bg-blue-menu");
                            btnUpdateQuestion.classList.remove("text-white");
                          }
                        }
                      }
                    }}
                    className={`bg-gray-50 text-gray-900 px-3 border-none leading-[18px] h-fit text-xs break-word whitespace-pre-wrap focus:outline-none rounded-lg`}
                    style={{
                      resize: "none",
                      minHeight: 18,
                      maxHeight: 88,
                      width: "100%",
                      overflowY: "auto",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(message, {
                        FORBID_TAGS: ["style"],
                      }),
                    }}
                  ></div>
                </div>

                <div className="flex flex-row gap-x-2 mb-2.5">
                  <button
                    className="hover:bg-gray-200 text-xs py-2 px-4 rounded-full"
                    onClick={() => {
                      setEditing(false);
                      setMessage(msg);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    id="btn-update-question"
                    className={`text-xs py-2 px-4 rounded-full bg-gray-300 text-gray-500`}
                    onClick={() => {
                      if (lastQuestionRef.current) {
                        document
                          .getElementById("btn-update-question")
                          ?.classList.contains("bg-blue-menu") &&
                          askChatbot(lastQuestionRef.current?.innerText, true);
                      }
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="group flex gap-x-2 mt-2 items-start justify-start">
              <div className="flex gap-x-2 items-start">
                <div className="flex flex-row items-center justify-center">
                  <Avatar
                    name={currentUser.name}
                    size={28}
                    url={currentUser.image_url}
                  />
                </div>
                <div
                  className={`relative bg-gray-100 rounded-lg whitespace-pre-wrap shadow-md text-xs py-2 px-5 w-fit ${
                    !lastQuestion && "mr-10"
                  }`}
                  style={{ overflowWrap: "anywhere" }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(msg),
                  }}
                ></div>
              </div>
              {lastQuestion && (
                <div
                  className="group-2 relative flex flex-col p-1 justify-center items-center h-8 w-8 min-w-[32px] opacity-100 md:opacity-0 duration-200 bg-gray-100 hover:bg-gray-200 md:group-hover:opacity-100 rounded-full cursor-pointer"
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  <PencilIcon className="h-4 w-4 text-gray-500 hover:text-gray-800" />
                  <div className="absolute z-top -right-2 invisible [.group-2:hover_&]:visible top-9 inline-block py-1 px-2 text-2xs w-max font-medium bg-gray-900 text-white rounded shadow-sm">
                    Edit text
                  </div>
                </div>
              )}
            </div>
          ))}
        {isReplying && index === messages.length - 1 && <Loading />}
      </div>
    );
  };

  const buildChatPanel = useMemo(
    () =>
      messages.length > 0 ? (
        <div ref={chatPanel} className={`pl-3 pr-1 py-1 rounded-lg`}>
          {messages.map((msg: any, index: number) => {
            return typeof msg === "string" ? (
              // eslint-disable-next-line react/no-array-index-key
              <QuestionComponent
                index={index}
                msg={msg}
                lastQuestion={messages.length - 2 === index}
              />
            ) : (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${index}`}>
                {msg.last_response ? (
                  <div className="group flex items-start gap-x-2 mt-2 justify-start">
                    <div className="flex items-start gap-x-2">
                      <div className="w-7 h-7 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full">
                        <Llama
                          customClass={"flex w-7 h-7 -mt-[3px] ml-[1px]"}
                          color="#fff"
                        />
                      </div>
                      <div className="flex flex-row gap-x-2 w-fit">
                        <div
                          className={`no-tailwindcss-base text-xs flex w-fit flex-col gap-y-4 val-content text-black break-words mr-auto rounded-lg shadow-md bg-white py-2 px-5 ${
                            beginResize && "select-none"
                          }`}
                        >
                          <MarkdownContent
                            message={msg.last_response}
                            copyStatus={copyStatus}
                            onCopyHandler={(children) =>
                              copyToClipboard(String(children), setCopyStatus)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="cursor-pointer"
                      title="Copy to clipboard"
                      onClick={() => {
                        copyToClipboard(
                          msg.last_response,
                          setCopyLastMsgStatus
                        );
                      }}
                    >
                      <div className="group-2 relative flex flex-col justify-center items-center h-8 w-8 min-w-[32px] opacity-100 md:opacity-0 duration-200 md:group-hover:opacity-100 bg-gray-100 hover:bg-gray-200 rounded-full cursor-pointer">
                        <ClipboardDocumentIcon className="h-5 w-5 duration-300 text-gray-400 [.group-2:hover_&]:text-gray-500 cursor-pointer" />
                        <div className="absolute z-top -right-1 invisible [.group-2:hover_&]:visible top-8 inline-block py-1 px-2 text-2xs w-max font-medium bg-gray-900 text-white rounded shadow-sm">
                          Copy to clipboard
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  msg.length > 0 && (
                    <div className="max-w-[70%] my-2 w-fit break-words mr-auto text-xs p-2 whitespace-pre-wrap text-red-500 val-content val-error">
                      {msg.map((err: string) => `${err}.\n`)}
                    </div>
                  )
                )}
              </div>
            );
          })}

          <div ref={messagesContainer}></div>
        </div>
      ) : (
        <>
          {agentSelected && showPromptGuide && (
            <PromptsGuideView
              onPromptSelected={onPromptSelected}
              onGetPrompts={onGetPrompts}
              promptSelecting={
                chatText.trim() === promptSelecting?.prompt
                  ? promptSelecting
                  : undefined
              }
              agent={agentSelected}
              showAskVal={showAskVal}
            />
          )}
        </>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      messages,
      agentSelected,
      copyStatus,
      copyLastMsgStatus,
      promptSelecting,
      chatText,
    ]
  );

  const getValContent = (allContent: HTMLCollectionOf<Element>) => {
    const contentArray = Array.from(allContent).map((ele, index) => ({
      innerHTML: ele.innerHTML,
      isError: ele.classList.contains("val-error"),
      isQuestion: index % 2 === 0,
    }));

    // Filter out error responses and the preceding questions that generated the error response
    const filteredContent = contentArray.filter((item, index, array) => {
      if (item.isError) return false;
      if (
        item.isQuestion &&
        index + 1 < array.length &&
        array[index + 1].isError
      )
        return false;
      return true;
    });

    const formattedContent = filteredContent.map((item, index, array) => {
      const isLastItem = index === array.length - 1;

      return `
        <div class="val-message">
        <p class="heading">${item.isQuestion ? "Question:" : "Response:"}</p>
        <div class="${item.isQuestion ? "question-detail" : "response-detail"}">
          ${item.innerHTML}
        </div>
      </div>
      ${!item.isQuestion && !isLastItem ? "<hr>" : ""}
      `;
    });
    return formattedContent.join("");
  };

  const rightContainerHeight = () => {
    let height = 0;
    if (chatContainerRef.current && textBoxRef.current) {
      let chatBoxHeight = 0;
      chatBoxHeight = textBoxRef.current?.getBoundingClientRect().height;

      if (attachmentFiles.length > 0) {
        chatBoxHeight += document.body.offsetWidth > 430 ? 36 : 0;
      }

      if (showPromptGuide) {
        height = document.body.offsetHeight - chatBoxHeight - 70;
      } else {
        // show chat
        if (chatAttachments && chatAttachments.length > 0) {
          //Has Attachments
          if (document.body.offsetWidth > 430) {
            // Desktop
            height = document.body.offsetHeight - chatBoxHeight - 68;
          } else {
            // mobile
            height = document.body.offsetHeight - chatBoxHeight - 68;
          }
        } else {
          height = document.body.offsetHeight - chatBoxHeight - 70;
        }
      }
    }

    return height;
  };

  return (
    <>
      <div
        id="chat-bot-view"
        className={`fixed z-10 right-0 top-0 bg-white h-full duration-500 shadow-lg ${
          showAskVal === true
            ? "visible translate-x-0"
            : "invisible translate-x-full"
        }`}
        onMouseUp={() => {
          setBeginResize(false);
          setOffSetY(0);
        }}
        onMouseMove={(event) => {
          if (beginResize && textBoxRef.current) {
            //drag up
            if (event.pageY < offSetY) {
              const newHeight = offSetY - event.pageY;
              if (newHeight > 0) {
                setBoxHeight(offSetHeight + newHeight);
              }
            }

            //drag down
            if (event.pageY > offSetY) {
              const newHeight = event.pageY - offSetY;
              if (newHeight > 0 && offSetHeight - newHeight > 110) {
                setBoxHeight(offSetHeight - newHeight);
              }
            }

            if (
              event.pageX < textBoxRef.current.getBoundingClientRect().left ||
              event.pageX > textBoxRef.current.getBoundingClientRect().right
            ) {
              setBeginResize(false);
            }
          }
        }}
      >
        <div className="flex flex-col h-full">
          <div className="h-full">
            <ResizableComponent
              expandedWidth={defaultWidth}
              expanded={expandHistory}
              setWidth={setWidth}
              width={expandHistory ? width : width - (defaultWidth ?? 0)}
              widthFull={!isTabletOrDesktop}
            >
              <div className="flex flex-row justify-end w-full h-full">
                <div
                  className={`flex flex-col h-full ${
                    expandHistory ? "" : "hidden"
                  } ${!isTabletOrDesktop ? "hidden" : ""}`}
                  style={{
                    maxWidth: `${defaultWidth}px`,
                    width: `${defaultWidth}px`,
                    minWidth: 210,
                  }}
                >
                  <div className="flex flex-col h-full pt-11 pb-3">
                    <div className="h-[50%]">
                      <AgentsView
                        currentAgent={agentSelected}
                        onItemChanged={(agent) => {
                          setShowPromptGuide(true);
                          createNewConversation();
                          setChatAttachments([]);
                          setAttachmentFiles([]);
                          setAgentSelected(agent);
                          localStorage.setItem(
                            CHAT_VAL_AGENT_KEY,
                            JSON.stringify(agent)
                          );
                        }}
                        onDeleteItem={() => {
                          setAgentSelected(undefined);
                        }}
                        showAskVal={showAskVal}
                      />
                    </div>

                    <hr />
                    <div className="h-[50%]">
                      <HistoryView
                        chatHistory={chatHistory}
                        currentConservationId={currentConservationId}
                        historyPage={historyPage}
                        setHistoryPage={setHistoryPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        setChatHistory={setChatHistory}
                        onItemChanged={(id: number) => {
                          setShowPromptGuide(false);
                          setChatAttachments([]);
                          setAttachmentFiles([]);
                          setPrompts([]);
                          resetChat();
                          if (!isReplying && id !== historyId) {
                            setHistoryId(id);
                          }
                        }}
                        onItemDeleted={(historyId: number) => {
                          if (!isReplying) {
                            setHistoryId(historyId);
                            setShowConfirmDeletion(true);
                            setDeletedHistory(false);
                          }
                        }}
                        showAskVal={showAskVal}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="flex flex-col mr-1 mt-2 h-full justify-between"
                  style={{
                    width: isTabletOrDesktop
                      ? expandHistory
                        ? `${width - (defaultWidth ?? 0)}px`
                        : `${width - (defaultWidth ?? 0) - 40}px`
                      : "93vw",
                  }}
                >
                  {/* Header */}
                  <div className="flex flex-row justify-between items-center py-2">
                    <div className="flex flex-row gap-x-3 items-center">
                      {isTabletOrDesktop ? (
                        <div>
                          <div
                            id="show-history-chat-button"
                            className="cursor-pointer"
                            onClick={() => {
                              setExpandHistory(!expandHistory);
                              setExpandValStatus(
                                VAL_HISTORY_STATUS_KEY,
                                !expandHistory
                              );
                              setHistoryTooltip(false);
                              setHideHistoryTooltip(false);
                            }}
                          >
                            {!expandHistory ? (
                              <div
                                className="relative"
                                onMouseOver={() => {
                                  setHistoryTooltip(true);
                                }}
                                onMouseLeave={() => {
                                  setHistoryTooltip(false);
                                }}
                              >
                                <ArchiveBoxIcon className="w-5 h-5 stroke-black" />
                                {showHistoryTooltip && Tooltip("Show History")}
                              </div>
                            ) : (
                              <div
                                className="relative"
                                onMouseOver={() => {
                                  setHideHistoryTooltip(true);
                                }}
                                onMouseLeave={() => {
                                  setHideHistoryTooltip(false);
                                }}
                              >
                                <ArchiveBoxIcon className="w-5 h-5 stroke-black" />
                                {hideHistoryTooltip && Tooltip("Hide History")}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <PopoverComponent>
                          <div className="flex flex-col justify-between h-full">
                            <div className="h-[46%]">
                              <AgentsView
                                currentAgent={agentSelected}
                                onItemChanged={(agent) => {
                                  setShowPromptGuide(true);
                                  createNewConversation();
                                  setAgentSelected(agent);
                                  localStorage.setItem(
                                    CHAT_VAL_AGENT_KEY,
                                    JSON.stringify(agent)
                                  );
                                }}
                                onDeleteItem={() => {
                                  setAgentSelected(undefined);
                                }}
                                showAskVal={showAskVal}
                              />
                            </div>
                            <hr />
                            <div className="h-[46%]">
                              <HistoryView
                                chatHistory={chatHistory}
                                historyPage={historyPage}
                                totalPage={totalPage}
                                setTotalPage={setTotalPage}
                                setHistoryPage={setHistoryPage}
                                currentConservationId={currentConservationId}
                                setChatHistory={setChatHistory}
                                onItemChanged={(id: number) => {
                                  setShowPromptGuide(false);
                                  setChatAttachments([]);
                                  setAttachmentFiles([]);
                                  resetChat();
                                  if (!isReplying && id !== historyId) {
                                    setHistoryId(id);
                                  }
                                }}
                                onItemDeleted={(historyId: number) => {
                                  if (!isReplying) {
                                    setHistoryId(historyId);
                                    setShowConfirmDeletion(true);
                                    setDeletedHistory(false);
                                  }
                                }}
                                isMobileMode={true}
                                showAskVal={showAskVal}
                              />
                            </div>
                          </div>
                        </PopoverComponent>
                      )}
                      <div>
                        <div
                          id="create-new-chat-button"
                          className="relative"
                          onMouseOver={() => {
                            setShowChatTooltip(true);
                          }}
                          onMouseLeave={() => {
                            setShowChatTooltip(false);
                          }}
                          onClick={() => {
                            createNewConversation();
                            initAgentFromLastChoice();
                          }}
                        >
                          <PlusIcon
                            className={`w-5 h-5 cursor-pointer ${
                              messages.length > 0
                                ? "stroke-black"
                                : "stroke-black"
                            }`}
                          />
                          {showChatTooltip && Tooltip("Create Chat")}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-x-5 items-center text-2xs ml-3 text-gray-900 font-semibold bg-slate-100 rounded-md px-2 py-1">
                      {agentSelected?.name}
                    </div>
                    <div className="flex flex-row gap-x-2 items-center">
                      <button
                        className="btn-primary-container px-2 py-1"
                        onClick={() => setShowCreateNoteModal(true)}
                      >
                        Save as Note
                      </button>
                      <Link
                        className="group relative"
                        target="_blank"
                        href={`/askval`}
                        onClick={() => {
                          localStorage.setItem(
                            CHAT_VAL_HISTORY_KEY,
                            historyId.toString()
                          );
                          localStorage.setItem(
                            CHAT_VAL_AGENT_KEY,
                            JSON.stringify(agentSelected)
                          );
                        }}
                      >
                        <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-black" />
                        <div className="absolute z-top right-0 invisible group-hover:visible top-6 inline-block py-1 px-2 text-2xs w-max font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip">
                          Ask Val Full Page
                        </div>
                      </Link>
                      <XMarkIcon
                        className="w-5 h-5 cursor-pointer"
                        onClick={() => {
                          setShowAskVal(false);
                          setExpandValStatus(VAL_STATUS_KEY, false);
                        }}
                      />
                      <NewModal
                        open={showCreateNoteModal}
                        onClose={setShowCreateNoteModal}
                      >
                        <CreateEditMeetingNote
                          valMessage={getValContent(
                            document.getElementsByClassName("val-content")
                          )}
                          setMeetingNote={setMeetingNote}
                          onSuccess={() => {
                            setShowCreateNoteModal(false);
                            setShowCreateNoteSuccessModal(true);
                          }}
                          isCreateNote={true}
                        />
                      </NewModal>
                      <NewModal
                        expanded={false}
                        showExpandButton={false}
                        open={showCreateNoteSuccessModal && !!meetingNote}
                        onClose={() => {
                          setShowCreateNoteSuccessModal(false);
                          setMeetingNote(undefined);
                        }}
                        buttons={[
                          <Link
                            key={"btnViewNote"}
                            href={`/meeting_notes/${meetingNote?.id}`}
                            className="btn-primary"
                            onClick={() => {
                              setShowCreateNoteSuccessModal(false);
                              setMeetingNote(undefined);
                              setShowAskVal(false);
                              setExpandValStatus(VAL_STATUS_KEY, false);
                            }}
                          >
                            View Note
                          </Link>,
                          <button
                            key={"btnClose"}
                            className="btn-secondary"
                            onClick={() => {
                              setShowCreateNoteSuccessModal(false);
                              setMeetingNote(undefined);
                            }}
                          >
                            Close
                          </button>,
                        ]}
                      >
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                            <CheckIcon
                              className="h-6 w-6 text-green-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title className="text-sm font-semibold leading-6 text-gray-900">
                              Successfully Created Note
                            </Dialog.Title>
                            <hr className="my-2" />
                            <div className="flex text-xs gap-x-6 flex-row items-center">
                              <div className="flex flex-row">
                                <div>
                                  <Link
                                    className="flex flex-col gap-x-2 text-black gap-y-2"
                                    onClick={() => {
                                      setShowCreateNoteSuccessModal(false);
                                      setMeetingNote(undefined);
                                      setShowAskVal(false);
                                      setExpandValStatus(VAL_STATUS_KEY, false);
                                    }}
                                    href={`/meeting_notes/${meetingNote?.id}`}
                                  >
                                    <div className="flex justify-between items-center"></div>
                                    <div className="flex flex-col gap-y-2">
                                      <div className="font-bold line-clamp-2">
                                        {meetingNote?.title}
                                      </div>
                                      <div className="line-clamp-4">
                                        {meetingNote?.content}
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </NewModal>
                    </div>
                  </div>
                  {/* Chat panel */}
                  <div className="h-full relative bg-gray-50">
                    <div
                      ref={rightContainerRef}
                      className="flex flex-col"
                      style={{
                        height: rightContainerHeight(),
                      }}
                    >
                      <div
                        ref={chatContainerRef}
                        className="flex flex-col !overflow-y-auto !overflow-x-hidden show-scrollbar justify-between rounded-md"
                        style={{
                          height: rightContainerHeight(),
                        }}
                      >
                        {buildChatPanel}
                      </div>
                      {chatAttachments && chatAttachments.length > 0 && (
                        <div className="flex flex-row gap-x-3 ml-5 mt-2">
                          {chatAttachments.map((file: Option) => (
                            <div
                              key={file.label}
                              className="relative group flex items-center bg-gray-200 p-1 w-fit rounded-lg"
                            >
                              <DocumentTextIcon
                                className="h-8 w-8 cursor-pointer"
                                onClick={() => {
                                  viewAndDownloadAttachment({
                                    fileId: file.value as number,
                                    fileName: file.label,
                                  });
                                }}
                              />
                              <div
                                className="absolute z-top -right-2 -top-1 invisible group-hover:visible cursor-pointer"
                                onClick={() => {
                                  setShowConfirmDeletionAttachment(true);
                                  setAttachmentId(file.value);
                                  setDeletedAttachment(false);
                                  forceUpdate();
                                }}
                              >
                                <XCircleIcon className="h-4 w-4 fill-white" />
                              </div>
                              <div className="absolute z-top -right-2 invisible group-hover:visible -top-8 inline-block py-1 px-2 text-2xs  w-max font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip">
                                {file.label}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div
                      ref={textBoxRef}
                      className="absolute bottom-5 w-full h-auto px-3"
                    >
                      {agentSelected && (
                        <div
                          className="h-2 rounded-full w-fit m-auto inset-x-0 absolute -top-1 z-10 bg-gray-300 flex flex-row items-center justify-center cursor-ns-resize"
                          onMouseDown={() => {
                            if (textBoxRef.current) {
                              setOffSetY(
                                textBoxRef.current.getBoundingClientRect().y
                              );
                              setOffSetHeight(
                                textBoxRef.current.getBoundingClientRect()
                                  .height
                              );
                              setBeginResize(true);
                            }
                          }}
                          onMouseUp={() => setBeginResize(false)}
                        >
                          <EllipsisHorizontalIcon className="h-4 w-4" />
                        </div>
                      )}
                      <div className="relative flex flex-col pb-2 bg-white rounded-lg border border-gray-300 h-auto">
                        {agentSelected ? (
                          <div
                            ref={textAreaRef}
                            suppressContentEditableWarning
                            contentEditable={true}
                            onBlur={(event: any) => {
                              setChatText(event.target.innerText);
                            }}
                            onPaste={(event: any) => {
                              event.preventDefault();
                              let paste = event.clipboardData
                                .getData("text")
                                .trim();
                              const selection = window.getSelection();
                              if (!selection?.rangeCount) return;
                              selection.deleteFromDocument();
                              selection
                                .getRangeAt(0)
                                .insertNode(document.createTextNode(paste));
                              selection.collapseToEnd();
                            }}
                            onCut={(event: any) => {
                              event.preventDefault();
                              let paste = event.clipboardData
                                .getData("text")
                                .trim();
                              const selection = window.getSelection();
                              if (!selection?.rangeCount) return;
                              selection.deleteFromDocument();
                              selection
                                .getRangeAt(0)
                                .insertNode(document.createTextNode(paste));
                              selection.collapseToEnd();
                            }}
                            onKeyUp={(event: any) => {
                              if (
                                event.target.getBoundingClientRect().height > 64
                              ) {
                                forceUpdate();
                              }
                              if (event.keyCode === 13) {
                                if (event.shiftKey) {
                                  event.stopPropagation();
                                } else {
                                  if (
                                    event.target.innerText.trim().length > 0 &&
                                    !isReplying
                                  ) {
                                    event.preventDefault();
                                    askChatbot(event.target.innerText.trim());
                                    event.target.innerText = "";
                                  }
                                }
                              } else {
                                const airIconChat = document.getElementById(
                                  "send-ai-chat-button"
                                );
                                if (airIconChat) {
                                  if (
                                    event.target.textContent.trim().length > 0
                                  ) {
                                    airIconChat.classList.remove("bg-gray-300");
                                    airIconChat.classList.add("bg-blue-menu");
                                  } else {
                                    airIconChat.classList.add("bg-blue-menu");
                                    airIconChat.classList.remove("bg-gray-300");
                                  }
                                }
                              }
                            }}
                            className={`peer text-gray-900 border-none text-xs rounded-lg mb-2 pb-0 pt-2 px-2 focus-visible:outline-none focus:ring-0 focus:border-gray-300 block w-full whitespace-pre-wrap`}
                            style={{
                              resize: "none",
                              maxHeight: 96,
                              overflowY: "auto",
                              minHeight: boxHeight - 46,
                            }}
                            placeholder={"Enter your prompt here"}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(chatHtml, {
                                FORBID_TAGS: ["style"],
                              }),
                            }}
                          ></div>
                        ) : (
                          <div
                            className={`translate-y-6 text-center text-gray-400 border-none text-xs rounded-lg w-full p-2 mb-2`}
                            style={{
                              height: 97,
                            }}
                          >
                            You are not able to continue the chat as there's no
                            agent
                          </div>
                        )}
                        <div className="flex flex-row gap-x-1 gap-y-2 flex-wrap items-center">
                          <div className="relative">
                            <Popover key={`model`}>
                              {({ open, close }) => (
                                <>
                                  <Popover.Button
                                    className={`flex flex-row items-center justify-between space-x-2 ml-3 bg-slate-100 rounded-md px-2 py-1
                      ${open ? "" : "text-opacity-90"} focus:outline-none
                      `}
                                  >
                                    <div className="text-2xs text-gray-900 font-semibold">{`${
                                      modelSelected?.label
                                        ? modelSelected?.label
                                        : ""
                                    }`}</div>

                                    <ChevronRightIcon className="w-3 h-3" />
                                  </Popover.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                  >
                                    <Popover.Panel
                                      className="z-top absolute w-52 left-0 md:right-0 "
                                      style={{
                                        top: -valModels.length * 34 - 25,
                                      }}
                                    >
                                      {valModels.length > 0 && (
                                        <div className="p-2 rounded-lg bg-white border border-gray-200 overflow-y-auto h-fit">
                                          {valModels.map((model: Option) => (
                                            <div
                                              key={model.value}
                                              className={`flex items-center rounded py-2 px-6 text-xs cursor-pointer ${
                                                modelSelected?.value ===
                                                model.value
                                                  ? "bg-blue-100 text-blue-900 font-semibold"
                                                  : "hover:bg-gray-100 font-normal"
                                              }`}
                                              onClick={() => {
                                                setModelSelected(model);
                                                localStorage.setItem(
                                                  "chatbotSelectedModel",
                                                  JSON.stringify(model)
                                                );
                                                close();
                                              }}
                                            >
                                              {model.label}
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </Popover.Panel>
                                  </Transition>
                                </>
                              )}
                            </Popover>
                          </div>
                          {prompts.length > 0 && (
                            <div className="relative text-2xs ml-2 text-gray-900 font-semibold bg-slate-100 rounded-md px-2 py-1">
                              <Popover key={`prompt`}>
                                {({ open, close }) => {
                                  return (
                                    <>
                                      <Popover.Button className={`text-2xs`}>
                                        <div className="flex flex-row space-x-2 items-center">
                                          Select Prompt
                                          <ChevronRightIcon className="w-3 h-3 ml-2" />
                                        </div>
                                      </Popover.Button>
                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                      >
                                        <Popover.Panel
                                          className={`z-top absolute left-0 md:right-0 w-60 p-2 rounded-lg bg-white border border-gray-200`}
                                          style={{
                                            top:
                                              prompts.length > 8
                                                ? -245
                                                : -prompts.length * 26 - 45,
                                          }}
                                        >
                                          <div
                                            className="w-full flex flex-row justify-end cursor-pointer"
                                            onClick={close}
                                          >
                                            <XMarkIcon className="h-5 w-5" />
                                          </div>
                                          <div className="overflow-y-auto h-fit max-h-[200px]">
                                            {prompts.map(
                                              (prompt: TemplatePrompt) => (
                                                <div
                                                  key={prompt.id}
                                                  className={`flex items-center rounded py-1 px-2 text-xs cursor-pointer line-clamp-1 ${
                                                    promptSelecting?.id ===
                                                      prompt.id &&
                                                    chatText.trim() ===
                                                      prompt.prompt.trim()
                                                      ? "bg-blue-100 text-blue-900 font-semibold"
                                                      : "hover:bg-gray-100 font-normal"
                                                  }`}
                                                  onClick={() => {
                                                    selectPrompt(prompt);
                                                    close();
                                                  }}
                                                >
                                                  {prompt.title}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Popover.Panel>
                                      </Transition>
                                    </>
                                  );
                                }}
                              </Popover>
                            </div>
                          )}
                          <OrganizationDropdown
                            organizationsSelected={organizationsSelected}
                            setOrganizationsSelected={setOrganizationsSelected}
                          />
                          {attachmentFiles && attachmentFiles.length > 0 && (
                            <div className="flex flex-row gap-x-3 ml-3">
                              {attachmentFiles.map((file: File) => (
                                <div
                                  key={file.name}
                                  className="relative group flex items-center bg-gray-200 p-1 w-fit rounded-lg"
                                >
                                  <DocumentTextIcon className="h-5 w-5" />
                                  <div
                                    className="absolute z-top -right-2 -top-1 invisible group-hover:visible cursor-pointer"
                                    onClick={() => {
                                      setAttachmentFiles(
                                        attachmentFiles.filter(
                                          (item) => item.name !== file.name
                                        )
                                      );
                                      forceUpdate();
                                    }}
                                  >
                                    <XCircleIcon className="h-4 w-4 fill-white" />
                                  </div>
                                  <div className="absolute z-top left-0 invisible group-hover:visible -top-8 inline-block py-1 px-2 text-2xs  w-max font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip">
                                    {file.name}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          <div className="flex flex-1 items-center justify-end gap-x-1 pr-1">
                            <div className="w-6 h-6 rounded-full p-1 cursor-pointer hover:bg-gray-200 bg-white drop-shadow-[0_0px_3px_rgba(0,0,0,0.25)]">
                              <Dropzone
                                disabled={isReplying || !agentSelected}
                                accept={{
                                  "application/pdf": [".pdf", ".PDF"],
                                }}
                                multiple={true}
                                onDrop={(acceptedFiles) => {
                                  // do nothing if no files
                                  if (acceptedFiles.length === 0) {
                                    return;
                                  }
                                  // on drop we add to the existing files
                                  const files = [
                                    ...attachmentFiles,
                                    ...acceptedFiles,
                                  ].reduce(
                                    (newAttachmentFiles: File[], obj) => {
                                      if (
                                        newAttachmentFiles.filter(
                                          (item) => item.name === obj.name
                                        ).length === 0
                                      ) {
                                        newAttachmentFiles.push(obj);
                                      }
                                      return newAttachmentFiles;
                                    },
                                    []
                                  );

                                  setAttachmentFiles(files);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <PaperClipIcon
                                      className={`h-4 w-4 -rotate-30 ${
                                        isReplying
                                          ? "stroke-gray-300"
                                          : "stroke-black"
                                      }`}
                                    />
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                            <div
                              id="send-ai-chat-button"
                              aria-disabled={isReplying || !agentSelected}
                              className={`w-6 h-6 rounded-full p-1 cursor-pointer ${
                                chatText.trim().length > 0
                                  ? "bg-blue-menu"
                                  : "bg-gray-300 "
                              }`}
                              onClick={() => {
                                if (chatText.trim().length > 0) {
                                  askChatbot(chatText);
                                  resetChat();
                                }
                              }}
                            >
                              <PaperAirplaneIcon
                                className={"h-4 w-4 stroke-white -rotate-45"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ConfirmationModal
                  open={showConfirmDeletionAttachment}
                  title="Delete attachment?"
                  subtitle="This action cannot be undone"
                  succeed={deletedAttachment}
                  succeedMessage={`You deleted this attachment.`}
                  processing={deletingAttachment}
                  processingText={"Deleting..."}
                  submitButtonText={"Delete"}
                  onClose={() => {
                    setAttachmentId(0);
                    setShowConfirmDeletionAttachment(false);
                  }}
                  onSubmit={() => deleteAttachment(attachmentId)}
                />

                <ConfirmationModal
                  open={showConfirmDeletion}
                  title="Delete chat?"
                  subtitle="This action cannot be undone"
                  succeed={deletedHistory}
                  succeedMessage={`You deleted this chat.`}
                  processing={deleting}
                  processingText={"Deleting..."}
                  submitButtonText={"Delete"}
                  onClose={() => {
                    setHistoryId(0);
                    setShowConfirmDeletion(false);
                  }}
                  onSubmit={() => deleteHistory(historyId)}
                />
                <ErrorModal
                  open={errorModal}
                  setOpen={setErrorModal}
                  errorMessage={errorMessage}
                />
              </div>
            </ResizableComponent>
          </div>
        </div>
      </div>
    </>
  );
}
