import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Fragment, SetStateAction, useEffect, useRef, useState } from "react";
import { Option } from "../../api/types";
import { classNames } from "../utils";

type SelectProps = {
  loadOptions?: (inputValue: string) => Promise<any>;
  formatOptionLabel?: any;
  value?: any;
  onChange?: any;
  options?: Option[];
  isMulti?: boolean;
  isClearable?: boolean;
  id?: string;
  placeholder?: string;
  setInputValue?: any;
  className?: string;
  creatable?: boolean;
  isGroup?: boolean;
  formatOptionLabelSelected?: any;
  onInputChange?: (newValue: string) => void;
  isAdditionalField?: boolean;
  isSmallSelect?: boolean;
  onBlur?: () => void;
  closeOptionsAfterSelect?: boolean;
};

type ChildrenComponentProps = {
  comboBoxButtonRef: any;
  selectedOptions: Option[];
  query: string;
  setQuery: React.Dispatch<SetStateAction<string>>;
  setSelectedOptions: React.Dispatch<SetStateAction<Option[]>>;
  open?: boolean;
  clearAll: () => void;
  listOptions: Option[] | GroupOptions[];
  setIsEnterPressed?: React.Dispatch<SetStateAction<boolean>>;
};

function Component({
  id,
  isMulti,
  value,
  onChange,
  comboBoxButtonRef,
  selectedOptions,
  formatOptionLabel,
  query,
  setQuery,
  isClearable = false,
  setSelectedOptions,
  clearAll,
  open,
  creatable = false,
  isGroup = false,
  formatOptionLabelSelected,
  onInputChange,
  listOptions,
  setIsEnterPressed,
  onBlur,
  closeOptionsAfterSelect,
  ...props
}: SelectProps & ChildrenComponentProps) {
  if (isMulti) {
    return (
      <Combobox value={value} onChange={onChange} multiple>
        {({ open }) => (
          <ChildrenComponent
            id={id}
            comboBoxButtonRef={comboBoxButtonRef}
            selectedOptions={selectedOptions}
            query={query}
            setQuery={setQuery}
            setSelectedOptions={setSelectedOptions}
            open={open}
            clearAll={clearAll}
            listOptions={listOptions}
            onInputChange={onInputChange}
            formatOptionLabel={formatOptionLabel}
            onChange={onChange}
            isMulti={isMulti}
            isClearable={isClearable}
            creatable={creatable}
            isGroup={isGroup}
            formatOptionLabelSelected={formatOptionLabelSelected}
            onBlur={onBlur}
            closeOptionsAfterSelect={closeOptionsAfterSelect}
            {...props}
          />
        )}
      </Combobox>
    );
  } else {
    return (
      <Combobox value={value} onChange={onChange}>
        {({ open }) => (
          <ChildrenComponent
            id={id}
            comboBoxButtonRef={comboBoxButtonRef}
            selectedOptions={selectedOptions}
            query={query}
            setQuery={setQuery}
            setSelectedOptions={setSelectedOptions}
            open={open}
            clearAll={clearAll}
            listOptions={listOptions}
            onInputChange={onInputChange}
            formatOptionLabel={formatOptionLabel}
            onChange={onChange}
            isClearable={isClearable}
            creatable={creatable}
            isGroup={isGroup}
            formatOptionLabelSelected={formatOptionLabelSelected}
            setIsEnterPressed={setIsEnterPressed}
            onBlur={onBlur}
            {...props}
          />
        )}
      </Combobox>
    );
  }
}

function ChildrenComponent({
  id,
  comboBoxButtonRef,
  selectedOptions,
  formatOptionLabel,
  query,
  onChange,
  setQuery,
  isMulti = false,
  isClearable = false,
  setSelectedOptions,
  clearAll,
  open,
  creatable = false,
  isGroup = false,
  formatOptionLabelSelected,
  onInputChange,
  listOptions,
  setIsEnterPressed,
  isSmallSelect,
  onBlur,
  closeOptionsAfterSelect,
  ...props
}: SelectProps & ChildrenComponentProps) {
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  return (
    <div className="relative">
      <div className="overflow-x-hidden flex min-h-[42px] h-auto w-full cursor-default flex-wrap rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
        <Combobox.Button
          id={id}
          className="flex items-center pl-2 pr-10 w-full"
          ref={comboBoxButtonRef}
          onKeyDown={(event: any) => {
            if (event.key === " ") {
              event.preventDefault();
              setQuery(query + " ");
              if (!open) {
                comboBoxButtonRef.current?.click();
              }
            } else if (event.key === "Backspace") {
              if (isMulti && selectedOptions.length > 0 && query.length === 0) {
                onChange(selectedOptions.slice(0, -1));
              }
            }
          }}
        >
          {!isMulti ? (
            formatOptionLabel ? (
              <div className="flex flex-wrap gap-x-1 gap-y-1 my-1 mr-8">
                {selectedOptions[0] && !query && formatOptionLabelSelected
                  ? formatOptionLabelSelected({
                      result: selectedOptions[0],
                    })
                  : formatOptionLabel(selectedOptions[0])}
                <Combobox.Input
                  placeholder={
                    selectedOptions.length > 0
                      ? ""
                      : props.placeholder || "Select..."
                  }
                  className={`absolute top-0 w-full h-full min-w-[1rem] flex flex-1 outline-none text-xs border-0 focus:ring-0 px-0 ${
                    query ? "bg-white" : "bg-transparent"
                  }`}
                  ref={inputRef}
                  value={query}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    if (!open) {
                      comboBoxButtonRef.current?.click();
                    }
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      setIsEnterPressed && setIsEnterPressed(true);
                    }
                  }}
                  onBlur={onBlur && onBlur}
                />
              </div>
            ) : (
              <>
                <Combobox.Input
                  placeholder={
                    selectedOptions.length > 0
                      ? ""
                      : props.placeholder || "Select..."
                  }
                  className={`absolute top-0 w-full h-full min-w-[1rem] flex flex-1 outline-none text-xs border-0 focus:ring-0 px-1 ${
                    query ? "bg-white" : "bg-transparent"
                  }`}
                  ref={inputRef}
                  value={query}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    if (!open) {
                      comboBoxButtonRef.current?.click();
                    }
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      setIsEnterPressed && setIsEnterPressed(true);
                    }
                  }}
                  onBlur={onBlur && onBlur}
                />
                <ul className="flex w-full flex-wrap gap-x-1 gap-y-1 my-1">
                  {selectedOptions.length > 0 && (
                    <li
                      className="py-1 px-1 rounded text-xs flex items-center"
                      key={selectedOptions[0].value}
                    >
                      {selectedOptions[0].label}
                    </li>
                  )}
                </ul>
              </>
            )
          ) : formatOptionLabel ? (
            <ul className="flex w-full flex-wrap gap-x-1 gap-y-1 my-0.5">
              {selectedOptions.map((option: Option) => (
                <li
                  className="z-0 max py-1 px-2 rounded bg-gray-200 text-2xs flex items-center"
                  key={option.value}
                >
                  {formatOptionLabelSelected
                    ? formatOptionLabelSelected({
                        result: option,
                      })
                    : formatOptionLabel(option)}
                  <XMarkIcon
                    className="w-4 h-4 ml-1 hover:text-red-600 cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedOptions((pre) => {
                        const newOptions = pre.filter(
                          (item) => item.value !== option.value
                        );
                        onChange && onChange(newOptions);
                        return newOptions;
                      });
                    }}
                  />
                </li>
              ))}
              <Combobox.Input
                placeholder={
                  selectedOptions.length > 0
                    ? ""
                    : props.placeholder ?? "Select..."
                }
                className={`min-w-[1rem] flex flex-1 outline-none text-xs border-0 focus:ring-0 px-0 ${
                  query ? "bg-white" : "bg-transparent"
                }`}
                value={query}
                onChange={(event) => {
                  setQuery(event.target.value);
                  if (!open) {
                    comboBoxButtonRef.current?.click();
                  }
                }}
                ref={inputRef}
                onBlur={onBlur && onBlur}
              />
            </ul>
          ) : (
            <ul className="flex w-full flex-wrap gap-x-1 gap-y-1 my-1">
              {selectedOptions.map((option: Option) => (
                <li
                  className="py-1 px-2 rounded bg-gray-200 text-2xs flex items-center"
                  key={option.value}
                >
                  {option.label}
                  <XMarkIcon
                    className="w-4 h-4 ml-1 hover:text-red-600 cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedOptions((pre) => {
                        const newOptions = pre.filter(
                          (item) => item.value !== option.value
                        );
                        onChange && onChange(newOptions);
                        return newOptions;
                      });
                    }}
                  />
                </li>
              ))}
              <Combobox.Input
                placeholder={
                  selectedOptions.length > 0
                    ? ""
                    : props.placeholder || "Select..."
                }
                className={`min-w-[1rem] flex flex-1 outline-none text-xs border-0 focus:ring-0 px-0 ${
                  query ? "bg-white" : "bg-transparent"
                }`}
                value={query}
                onChange={(event) => {
                  setQuery(event.target.value);

                  if (!open) {
                    comboBoxButtonRef.current?.click();
                  }
                }}
                ref={inputRef}
                onBlur={onBlur && onBlur}
              />
            </ul>
          )}
          <div className="absolute right-2 h-full flex items-center space-x-1 text-gray-400">
            {isClearable && selectedOptions.length > 0 && (
              <XMarkIcon
                className="h-5 w-5 cursor-pointer"
                onClick={clearAll}
              />
            )}
            <div className="h-full flex items-center">
              <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </div>
        </Combobox.Button>
      </div>
      <Transition
        as={Fragment}
        show={open}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => {
          setQuery("");
        }}
      >
        <Combobox.Options className="absolute z-top mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 ring-1 ring-black/5 focus:outline-none text-xs border shadow-md">
          {creatable && query.length > 0 && (
            <Combobox.Option
              key={-1}
              value={{ value: query, label: query, isNew: true }}
              className={
                "bg-blue-600 text-white relative cursor-default select-none py-2 pl-3 pr-9"
              }
            >
              Create "{query}"
            </Combobox.Option>
          )}

          {(listOptions ?? []).length === 0 ? (
            <div
              className="relative cursor-default select-none px-4 py-2 text-gray-700"
              key={-2}
            >
              Nothing found.
            </div>
          ) : !isGroup ? (
            (listOptions as Option[]).map((option) => (
              <Combobox.Option
                key={option.value}
                className={({ active }) =>
                  classNames(
                    active ? "bg-blue-500 text-white" : "text-gray-900",
                    "relative cursor-default select-none py-2",
                    isSmallSelect ? "" : "pl-3 pr-9"
                  )
                }
                value={option}
                onClick={() => {
                  if (open && closeOptionsAfterSelect) {
                    comboBoxButtonRef.current?.click();
                  }
                }}
              >
                {({ selected, active }) => {
                  const isOptionSelected = selectedOptions.find(
                    (item) => item.value.toString() === option.value.toString()
                  );
                  return (
                    <>
                      {formatOptionLabel ? (
                        formatOptionLabel(option)
                      ) : (
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              isOptionSelected
                                ? "font-semibold"
                                : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {option.label}
                          </span>
                        </div>
                      )}

                      {isOptionSelected ? (
                        <span
                          className={classNames(
                            active ? "text-white" : "text-blue-600",
                            "absolute inset-y-0 right-0 flex items-center",
                            isSmallSelect ? "" : "pr-4"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  );
                }}
              </Combobox.Option>
            ))
          ) : (
            (listOptions as GroupOptions[]).map((group) => {
              return (
                <div key={group.label}>
                  {group.options.length > 0 && (
                    <p className="px-3 py-2 text-gray-500">{group.label}</p>
                  )}
                  {group.options.map((option) => (
                    <Combobox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-blue-500 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2",
                          isSmallSelect ? "" : "pl-3 pr-9"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => {
                        const isOptionSelected = selectedOptions.find(
                          (item) =>
                            item.value.toString() === option.value.toString()
                        );
                        return (
                          <>
                            {formatOptionLabel ? (
                              formatOptionLabel(option)
                            ) : (
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    isOptionSelected
                                      ? "font-semibold"
                                      : "font-normal",
                                    "ml-3 block truncate"
                                  )}
                                >
                                  {option.label}
                                </span>
                              </div>
                            )}

                            {isOptionSelected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-blue-600",
                                  "absolute inset-y-0 right-0 flex items-center",
                                  isSmallSelect ? "" : "pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        );
                      }}
                    </Combobox.Option>
                  ))}
                </div>
              );
            })
          )}
        </Combobox.Options>
      </Transition>
    </div>
  );
}

type GroupOptions = {
  label: string;
  options: Option[];
};

export default function Select({
  loadOptions,
  formatOptionLabel,
  value,
  onChange,
  options,
  isMulti = false,
  isClearable = false,
  id = "",
  setInputValue,
  className = "",
  creatable = false,
  isGroup = false,
  formatOptionLabelSelected,
  onInputChange,
  isAdditionalField = false,
  isSmallSelect = false,
  onBlur,
  ...props
}: SelectProps) {
  const comboBoxButtonRef = useRef<any>(null);

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [query, setQuery] = useState("");
  const [listOptions, setListOptions] = useState<Option[] | GroupOptions[]>([]);
  const [isEnterPressed, setIsEnterPressed] = useState<boolean>(false);

  useEffect(() => {
    setInputValue && setInputValue(query);
    if (loadOptions) {
      loadOptions(query).then((items) => {
        setListOptions(items);
      });
    }
    // eslint-disable-next-line
  }, [loadOptions, query]);

  useEffect(() => {
    if (options) {
      setListOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (value) {
      if (!Array.isArray(value)) {
        setSelectedOptions([value]);
      } else {
        setSelectedOptions(value);
      }
    } else {
      setSelectedOptions([]);
    }
    //eslint-disable-next-line
  }, [value, listOptions]);

  const onSelectedChange = async (
    item: (Option & { isNew: boolean }) | (Option & { isNew: boolean })[]
  ) => {
    setQuery("");
    if (!Array.isArray(item)) {
      setSelectedOptions([item]);
      if (item.label === item.value) {
        onChange && (isAdditionalField ? await onChange(item) : onChange(item));
        !item.isNew && (await comboBoxButtonRef?.current?.click());
      } else {
        onChange && onChange(item);
      }
    } else {
      let tempItems: Option[] = item.filter(
        (it) =>
          item.filter((dt) => dt.value.toString() === it.value.toString())
            .length < 2
      );
      setSelectedOptions([...tempItems]);
      onChange && onChange([...tempItems]);
    }
  };

  useEffect(() => {
    if (isEnterPressed && !isMulti && isAdditionalField) {
      comboBoxButtonRef?.current?.click();
      setIsEnterPressed(false);
    }
    // eslint-disable-next-line
  }, [isEnterPressed]);

  useEffect(() => {
    if (onInputChange) {
      onInputChange(query);
    } else {
      if (query === "") {
        setListOptions(options as Option[]);
      } else {
        const newOptions = (options || []).filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase())
        );
        setListOptions(newOptions as Option[]);
      }
    }
    //eslint-disable-next-line
  }, [query]);

  const clearAll = () => {
    setSelectedOptions([]);
    if (isMulti) {
      onChange && onChange([]);
    } else {
      onChange && onChange(null);
    }
  };

  return (
    <div className={`border rounded-md border-1 border-gray-200 ${className}`}>
      <Component
        id={id}
        {...props}
        value={selectedOptions}
        onChange={onSelectedChange}
        isMulti={isMulti}
        comboBoxButtonRef={comboBoxButtonRef}
        selectedOptions={selectedOptions}
        query={query}
        setQuery={setQuery}
        setSelectedOptions={setSelectedOptions}
        clearAll={clearAll}
        listOptions={listOptions}
        onInputChange={onInputChange}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable}
        creatable={creatable}
        isGroup={isGroup}
        formatOptionLabelSelected={formatOptionLabelSelected}
        setIsEnterPressed={setIsEnterPressed}
        isSmallSelect={isSmallSelect}
        onBlur={onBlur}
      />
    </div>
  );
}
