import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PropsWithChildren, useEffect, useState } from "react";

type SnackbarProps = {
  message: string;
  duration?: number; //duration in milliseconds
  onClose: () => void;
};

type ComponentProps = {
  isPreventClickOutside?: boolean;
};

function Component({
  children,
  isPreventClickOutside,
}: PropsWithChildren<ComponentProps>) {
  if (isPreventClickOutside) {
    return (
      <Dialog as="div" open={true} onClose={() => {}} className="w-0 h-0">
        {children}
      </Dialog>
    );
  } else {
    return <div className="w-0 h-0">{children}</div>;
  }
}

function Snackbar({
  message,
  duration = 3000,
  onClose,
  isPreventClickOutside = false,
}: SnackbarProps & ComponentProps) {
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    setShowSnackbar(true);
    if (duration) {
      const timer = setTimeout(() => {
        onClose();
        setShowSnackbar(false);
      }, duration);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [message, duration]);

  if (!showSnackbar) {
    return <></>;
  }

  return (
    <Component isPreventClickOutside={isPreventClickOutside}>
      <div
        id="snackbar"
        className={`absolute bottom-8 transform left-1/2 -translate-x-1/2 z-top flex items-center pointer-events-auto overflow-hidden rounded-lg text-white bg-black shadow-lg ring-1 ring-black ring-opacity-5 h-12 px-6 py-4 gap-x-2 text-2xs w-max md:text-xs`}
      >
        {message}
        <button
          type="button"
          className="text-gray-500"
          onClick={() => {
            setShowSnackbar(false);
          }}
        >
          <XMarkIcon
            className="h-6 w-6 text-white"
            aria-hidden="true"
            onClick={onClose}
          />
        </button>
      </div>
    </Component>
  );
}

export default Snackbar;
