export const SUPPORT_MESSAGE = (
  <p>
    Please contact the Labs Support team at{" "}
    <a href="mailto:labs.support@vertexholdings.com">
      labs.support@vertexholdings.com
    </a>{" "}
    for assistance
  </p>
);

export const ShowSnackBar = (
  message: string,
  isPreventClickOutside: boolean = false
) => {
  document.dispatchEvent(
    new CustomEvent("NOTIFICATION_MESSAGE", {
      detail: {
        message,
        isPreventClickOutside,
      },
    })
  );
};
